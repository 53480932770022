import dayjs, { Dayjs } from "dayjs";

export function parseDate(date: string | null | undefined): Dayjs | null {
    try {
        if (typeof date == "string" && date.length > 6) {
            return dayjs(date, "YYYY-MM-DD");
        }
    } catch (error) {
        console.warn("Could not parse date", date, error);
    }

    return null;
}