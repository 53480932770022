import { UserInfos } from "../../types/UserInfos"
import { Button, Stack, Drawer, Chip } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import React from "react";
import dayjs from "dayjs";
import { Membership } from "../../types/Membership";

type PopUpMembershipsProps = {
    members: UserInfos[],
    onSelect: (member: UserInfos) => void,
    open: boolean,
    onClose: () => void,
}

export const PopUpMemberships = ({ members, onSelect, open, onClose }: PopUpMembershipsProps) => {
    const [pageSize, setPageSize] = React.useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        name: true,
        surname: true,
        city: false,
        id: true,
        start_at: true,
        end_at: true,
        active: true,
        vp_title: true,
        details: true,
    });
    const memberships = Membership.fromJsonArray(members);

    const columns = [
        { field: "name", headerName: "Name", flex: 2 },
        { field: "surname", headerName: "Nachname", flex: 2 },
        { field: "city", headerName: "Wohnort", flex: 2 },
        { field: "id", headerName: "ID", flex: 1 },
        {
            field: "start_at", headerName: "Startdatum", flex: 1.5, renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }) : ""
        },
        {
            field: "end_at", headerName: "Enddatum", flex: 1.5, renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }) : ""
        },
        {
            type: "singleSelect",
            valueOptions: [
                { label: 'Warteliste', value: 'WaitingList' },
                { label: 'Passiv', value: 'NoVegetables' },
                { label: 'Aktiv', value: 'Active' },
                { label: 'Probe', value: 'TestMembership' },
                { label: 'Team', value: 'Team' },
                { label: 'Umsonstanteil', value: 'FreeVegetables' },
                { label: 'Ausgetreten', value: 'Ausgetreten' },
            ],
            field: "subscription",
            headerName: "Status",
            flex: 1.8,
            valueGetter: (params) => {
                if (params.row.end_at) {
                    return "Ausgetreten"
                }

                return params.row.subscription;
            },
            renderCell: (params) => {
                const member: Membership = params.row
                let { color, label, whiteText, opacity } = member.getLabelAndColor(dayjs())

                return <Chip size="small" label={label} sx={{
                    bgcolor: color,
                    color: whiteText ? "white" : "black",
                    opacity: opacity ? 0.5 : 1
                }} />
            }
        },
        {
            field: "vp_title",
            headerName: "VP",
            flex: 1,
            renderCell: (props) =>
                props.row.vp_path && (
                    <Link target="_blank" to={`/${props.row.vp_path}`}>{props.row.vp_code}</Link>
                ),
        },
        {
            headerName: "",
            key: "details",
            flex: 4,
            renderCell: (props) => {
                const member = members.find((member) => member.id === props.row.id);
                return (
                    <Stack direction="row" spacing={3}>
                        <Button LinkComponent={Link} target="_blank" to={`/admin_membership_${props.row.id}`}>Details</Button>
                        <Button variant="outlined" color="success" onClick={() => onSelect(member!)}>Auswählen</Button>
                    </Stack>
                )
            }
        },
    ];
    return (
        <Stack justifyContent="center">
            <Drawer
                anchor="bottom"
                open={open}
                onClose={onClose}
                sx={{
                    maxWidth: "75%",
                }}>
                <Stack alignItems={"flex-end"} sx={{ width: "100%" }}>
                    <Button onClick={onClose} sx={{ margin: 2 }}><Close /></Button>
                </Stack>
                <div style={{ display: "flex", height: "100%", justifyContent: "center" }}>
                    <div style={{ flexGrow: 0.6 }}>
                        <DataGridPro
                            autoHeight
                            components={{ Toolbar: GridToolbar }}
                            columns={columns}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    // focus is lost if active
                                    //quickFilterProps: { debounceMs: 200 },
                                }
                            }}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                            pagination
                            pageSize={pageSize}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rows={memberships}
                            sx={{ height: "500px" }}
                        />
                    </div>
                </div>
            </Drawer>
        </Stack>
    )
}
