import { Typography, Stack, Button } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import { NavLink } from "react-router-dom";
import { sendToBackend } from "../helper/websocketHelper";

export function Invitation({data}) {
    console.log("data: 1", data)
    const document = data.interaction.accept_invitation;

    const Wrapper = styled(Stack)(() => ({
        padding: "0 20px",
    
        [theme.breakpoints.up("lg")]: {
          padding: "20px 0",
        },
    
        ".vp": {
          "&__infobox": {
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 20,
            margin: "0 20px 50px",
    
            [theme.breakpoints.up("lg")]: {
              gap: 100,
            },
          },
        },
      }));


  return (
    <Wrapper>
        <Typography variant="h4">{document.caption}</Typography>
        {document.show_buttons &&
        (
            <Stack direction="row" alignItems="center" justifyContent="center" gap={2} marginTop={4}>
                <Button variant="contained" onClick={()=>sendToBackend("accept_invitation")}>{document.yes}</Button>
                <NavLink to="/">
                    <Button variant="outlined">
                        {document.no}
                    </Button>
                </NavLink>
            </Stack>
        )
        }

    </Wrapper>
  )
}