import { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  Box,
  InputBase,
  Table,
  TableCell,
  Stack,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  CalendarMonth,
  Description,
  Search as SearchIcon,
  Delete,
  Close,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import { search } from "../helper/websocketHelper";
import { useNavigate } from "react-router-dom";

const Search = ({ searchFilters, loggedIn, staticTexts }) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [filterDocumentTeam, setFilterDocumentTeam] = useState("");
  const [filterDocumentTopic, setFilterDocumentTopic] = useState("");
  const [filterEventCategory, setFilterEventCategory] = useState("");
  const [term, setTerm] = useState("");
  const [searchType, setSearchType] = useState("both");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSearchResults([]); //TODO!
  };

  const navigate = useNavigate();
  const handleRowClick = (path) => {
    navigate(path);
  };

  const handleFilterChange = (event) => {
    if (event.target.name === "document_team") {
      setFilterDocumentTeam(event.target.value);
    } else if (event.target.name === "document_topic") {
      setFilterDocumentTopic(event.target.value);
    } else if (event.target.name === "events_category") {
      setFilterEventCategory(event.target.value);
    }
  };

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    let searchTimeout = null;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      search({
        type: searchType,
        term: term,
        document_team: filterDocumentTeam,
        document_topic: filterDocumentTopic,
        event_category: filterEventCategory,
      }).then((result) => {
        setSearchResults(result.results);
      });
    }, 300);
  }, [
    term,
    filterDocumentTeam,
    filterDocumentTopic,
    filterEventCategory,
    searchType,
  ]);

  const getIcon = (type) => {
    if (type === "event") {
      return <CalendarMonth />;
    }
    if (type === "document") {
      return <Description />;
    }
    return null;
  };

  return (
    <>
      <Button onClick={handleOpen}>
        <SearchIcon />
      </Button>
      <Modal open={open} onClose={handleClose} autoFocus={false}>
        <InnerModal>
          <CloseButton onClick={() => setOpen(false)}>
            <Close />
          </CloseButton>
          <TableContainer>
            <HeaderContainer className={searchResults.length > 0 ? "border" : ""}>
              <Box sx={{ flex: "0 1 20%", borderBottom: "none", padding: "10px 0" }}>
                {loggedIn && (
                  <FormControl size="small">
                    <InputLabel>{staticTexts.search_type || "bla"}</InputLabel>
                    <Select
                      label={staticTexts.search_type}
                      name="type"
                      id="type_select"
                      onChange={(e) => setSearchType(e.target.value)}
                      value={searchType}
                      sx={{ minWidth: "130px" }}
                    >
                      <MenuItem value="both">{staticTexts.search_type_all}</MenuItem>
                      <MenuItem value="documents">
                        <Description sx={{ paddingRight: "10px" }} />
                        {staticTexts.search_type_documents}
                      </MenuItem>
                      <MenuItem value="events">
                        <CalendarMonth sx={{ paddingRight: "10px" }} />
                        {staticTexts.search_type_events}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Box>
              <SearchContainer>
                <StyledInputBase
                  inputRef={inputRef}
                  placeholder={staticTexts.search_input || "bla"}
                  onChange={(e) => setTerm(e.target.value)}
                  autoFocus={true}
                />
                <Stack
                  direction="row"
                  gap="10px"
                  sx={{ minWidth: "fit-content" }}
                  id="filterbar"
                >
                  {searchType === "documents" && (
                    <>
                      <FormControl size="small">
                        <InputLabel>Team</InputLabel>
                        <SelectWithDeleteButton
                          label={staticTexts.search_document_team}
                          name="document_team"
                          id="document_team_select"
                          onChange={handleFilterChange}
                          value={filterDocumentTeam}
                          className={filterDocumentTeam ? "select--open" : ""}
                        >
                          {searchFilters.document_teams.map((e) => (
                            <MenuItem key={e} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                        </SelectWithDeleteButton>
                        {filterDocumentTeam !== "" && (
                          <DeleteButton
                            aria-label="delete"
                            onClick={() => setFilterDocumentTeam("")}
                          >
                            <Delete />
                          </DeleteButton>
                        )}
                      </FormControl>

                      <FormControl size="small" sx={{ width: "fit-content" }}>
                        <InputLabel>Thema</InputLabel>
                        <SelectWithDeleteButton
                          label={staticTexts.search_document_topic}
                          name="document_topic"
                          id="document_topic_select"
                          onChange={handleFilterChange}
                          value={filterDocumentTopic}
                          className={
                            filterDocumentTopic ? "select--open" : ""
                          }
                        >
                          {searchFilters.document_topics.map((e) => (
                            <MenuItem key={e} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                        </SelectWithDeleteButton>
                        {filterDocumentTopic !== "" && (
                          <DeleteButton
                            aria-label="delete"
                            onClick={() => setFilterDocumentTopic("")}
                          >
                            <Delete />
                          </DeleteButton>
                        )}
                      </FormControl>
                    </>
                  )}
                  {searchType === "events" && (
                    <>
                      <FormControl size="small" sx={{ width: "fit-content" }}>
                        <InputLabel>Thema</InputLabel>
                        <SelectWithDeleteButton
                          label={staticTexts.search_event_category}
                          name="events_category"
                          id="events_category_select"
                          onChange={handleFilterChange}
                          value={filterEventCategory}
                          className={
                            filterEventCategory ? "select--open" : ""
                          }
                        >
                          {searchFilters.event_categories.map((e) => (
                            <MenuItem key={e} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                        </SelectWithDeleteButton>
                        {filterEventCategory !== "" && (
                          <DeleteButton
                            aria-label="delete"
                            onClick={() => setFilterEventCategory("")}
                          >
                            <Delete />
                          </DeleteButton>
                        )}
                      </FormControl>
                    </>
                  )}
                </Stack>
              </SearchContainer>
            </HeaderContainer>
            <Table stickyHeader>
              <TableHead></TableHead>
              {searchResults.length > 0 && (
                <>
                  <TableBody sx={{ overflow: "auto" }}>
                    {searchResults.map((e) => {
                      if (
                        e.type === "document" &&
                        (searchType === "documents" || searchType === "both")
                      ) {
                        return (
                          <TableRow
                            key={`document-${e._id}`}
                            hover
                            onClick={() => handleRowClick(e.path)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              textDecoration: "none",
                            }}
                          >
                            <TableCell>{getIcon(e.type)}</TableCell>
                            <TableCell>{e.title}</TableCell>
                          </TableRow>
                        );
                      } else if (
                        e.type === "event" &&
                        (searchType === "events" || searchType === "both")
                      ) {
                        return (
                          <TableRow
                            key={`event-${e._id}`}
                            hover
                            onClick={() => handleRowClick(e.path)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              textDecoration: "none",
                            }}
                          >
                            <TableCell>{getIcon(e.type)}</TableCell>
                            <TableCell>
                              {`${new Date(e.start_at).toLocaleDateString(
                                "de-DE",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )} - ${e.title}`}
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </InnerModal>
      </Modal>
    </>
  );
};

export default Search;

const InnerModal = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "0",
  left: "50%",
  transform: "translate(-50%, 0)",
  width: "100vw",
  maxHeight: "90vh",
  background: "white",
  border: `2px solid ${theme.palette.darkgreen}`,
  boxShadow: 24,
  padding: "20px",
  overflow: "auto",

  "& .MuiTableContainer-root": {
    minWidth: "100%",
  },

  [theme.breakpoints.up("sm")]: {
    top: "10vh",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "50vw",

    "& .MuiTableContainer-root": {
      minWidth: 800,
    },
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "5px",
  right: "20px",

  [theme.breakpoints.up("sm")]: {
    top: "2px",
    right: "2px"
  }
}))

const HeaderContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "center",
  },

  "&.border": {
    borderBottom: `2px solid ${theme.palette.darkgreen}`,
  }
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "center",
  gap: "20px",
  borderBottom: "none",
  padding: "0 0 16px",

  [theme.breakpoints.up("sm")]: {
    padding: "16px 25px 16px 16px",
    flex: "0 1 80%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    gap: 0,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  "& .MuiInputBase-input": {
    width: "100%",
    textAlign: "center",
  },

  [theme.breakpoints.up("sm")]: {
    "& .MuiInputBase-input": {
      textAlign: "start",
    },
  },
}));

const SelectWithDeleteButton = styled(Select)(() => ({
  position: "relative",
  minWidth: "100px",

  "& svg": {
    right: "7px",
  },

  "&.select--open": {
    paddingRight: "30px",

    "& svg": {
      right: "32px",
    },
  },
}));

const DeleteButton = styled(IconButton)(() => ({
  position: "absolute",
  top: 0,
  right: 0,
}));
