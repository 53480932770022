import { useState } from "react";
import { Box, Dialog, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../../theme";
import { url } from "../../helper/websocketHelper";
import CloseIcon from "@mui/icons-material/Close";
import RotateRight from "@mui/icons-material/RotateRight"
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

function ImageModal({ images, image, className }) {
  const [imageOpen, setImageOpen] = useState(false);
  const [rotation, setRotation] = useState(0)

  const handleClose = () => {
    setImageOpen(false);
  };

  // rotate image on buttonclick
  let angle = [0, 90, 180, 270];
  const rotateRight = () => {
    setRotation(rotation + 1);
    if (rotation === 4) setRotation(0);
  };

  return (
    <Box>
      <ImagePreview
        src={`${url}${image}`}
        onClick={() => setImageOpen(true)}
        className={className}
      />
      <Dialog
        open={imageOpen}
        onClose={handleClose}
        maxWidth={false} // Verhindert automatische Größenbeschränkungen durch MUI
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <ButtonBox>
          <StyledButton onClick={rotateRight}>
            <RotateRight />
          </StyledButton>
          <StyledButton onClick={() => setImageOpen(false)}>
            <CloseIcon />
          </StyledButton>
        </ButtonBox>
        <ModalBox>
          <CustomSplideContainer
            onMove={()=>setRotation(0)}
            options={{
              rewind: true,
              arrows: images.length > 1 ? true : false,
            }}
          >
            {images.map((image, index) => {
              return (
                <SplideSlide key={image} className="splide__slide">
                  <img
                    style={
                      rotation !== 0
                        ? { transform: `rotate(${angle[rotation]}deg)` }
                        : { transform: "rotate(0)" }
                    }
                    className="splide__image"
                    src={`${url}${image}`}
                    alt={`image-${index}`}
                  />
                </SplideSlide>
              );
            })}
          </CustomSplideContainer>
        </ModalBox>
      </Dialog>
    </Box>
  );
}

export default ImageModal;

const ImagePreview = styled("img")(() => ({
  backgroundSize: "contain",
  height: "auto",
  width: "100%",
  maxWidth: "100%",
  maxHeight: "unset",
  backgroundRepeat: "no-repeat",
  padding: "0 0 30px 0",
  cursor: "zoom-in",
  transform: "scale(1)",
  transition: "transform 250ms ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
    transition: "transform 250ms ease-in-out",
  },
  [theme.breakpoints.up("md")]: {
    padding: "0 0 30px 0",
  },
}));

const ModalBox = styled(Box)(() => ({
  background: theme.palette.black,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

const ButtonBox = styled(Box)(() => ({
  position: "fixed",
  zIndex: 5,
  top: 20,
  right: 0,
  display: "flex",
  justifyContent: "space-evenly",
  gap: 20,
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    width: "fit-content",
    right: 20,
  },
}));

const StyledButton = styled(IconButton)(() => ({
  color: theme.palette.black,
  background: `${theme.palette.white}ff`,
  transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
    background: `${theme.palette.lightgreen}ff`,
  },
}));

const CustomSplideContainer = styled(Splide)(() => ({
  maxWidth: "70vw",
  width: "fit-content",
  
  "& .splide__slide": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .splide__image": {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "80vh",
  },
}));