import React, { useState } from 'react'
import { sendToBackend } from '../../helper/websocketHelper';
import { Stack, TextField, Button, Typography, InputAdornment, IconButton, Dialog, Grid } from '@mui/material';
import { Delete, Close } from '@mui/icons-material';
import { UserInfos } from '../../types/UserInfos';
import { Bidding } from '../../types/Bidding';
import { BidYear } from '../../types/BidYear';
import { formatEuroToCents } from '../FieldBuilder';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { parseDate } from '../../helper/dateHelper';

export type AdminMembershipTabBiddingProps = {
  userInfos: UserInfos;
  biddingHistory: Bidding[];
  bidYears: BidYear[];
}

const getBeginOfNextMonth = () => {
  let now = dayjs();
  let year = now.year();
  let month = now.month();
  month += 2;
  if (month > 12) {
    month -= 12;
    year += 1;
  }
  return `${year}-${month}-1`;
};

export default function AdminMembershipTabBidding({ userInfos, biddingHistory, bidYears }: AdminMembershipTabBiddingProps) {

  const [openBiddingModal, setOpenBiddingModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedBidding, setSelectedBidding] = useState<Bidding | undefined>(undefined);
  const [bidDate, setBidDate] = useState<string>(getBeginOfNextMonth());
  const removeBidding = () => {
    sendToBackend("remove_bidding", { index: selectedBidding?.index })
    setSelectedBidding(undefined)
    setOpenDeleteModal(false)
  }

  return (
    <>
      <Stack direction="column" sx={{ margin: 5 }} justifyContent="center" gap={2} alignItems="center">
        <Typography variant='h5'>Aktuell Gebotener Betrag: {biddingHistory.length === 0 ? "-" : biddingHistory[biddingHistory.length - 1].euros + ' €'} </Typography>
        <Button onClick={() => setOpenBiddingModal(true)} variant="contained">Gebot bearbeiten</Button>
      </Stack>

      <Dialog open={openBiddingModal} onClose={() => setOpenBiddingModal(false)} sx={{ padding: 3 }}>
        <Stack
          sx={{ margin: "20px" }}
          justifyContent="center"
          alignItems="center"
          gap={2}
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            sendToBackend("add_bidding", {
              cents: formatEuroToCents((document.getElementById("bidding_admin_input") as null | HTMLInputElement)?.value),
              start_at: bidDate
            });
            setOpenBiddingModal(false);
          }}
          noValidate
          autoComplete="off"
        >
          <Button onClick={() => setOpenBiddingModal(false)} sx={{ position: "absolute", top: 10, right: 10 }}><Close /></Button>
          <Typography variant="h5">Gebot bearbeiten</Typography>
          <Stack direction="row" spacing={1}>
            <TextField
              className="bidding__details__input"
              margin="none"
              id="bidding_admin_input"
              name="bidding_admin_input"
              size="medium"
              label="Gebot"
              autoFocus
              color="secondary"
              placeholder={biddingHistory.length === 0 ? "-" : biddingHistory[biddingHistory.length - 1].euros.toString()}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <Typography variant="caption">datum_irgendwass</Typography> */}
              <DatePicker
                renderInput={(props) => <TextField variant="outlined" fullWidth {...props} />}
                // label="DateTimePicker"
                value={bidDate}
                views={["month", "year"]}
                label="Start ab"
                inputFormat="DD.MM.YYYY"
                // inputFormat={"E, d. M Y"}
                onChange={(newValue: any) => {
                  // console.log("New value", newValue?.format('YYYY-MM-DD'));
                  setBidDate(newValue?.format('YYYY-MM-DD'))
                }}
              />
            </LocalizationProvider>
          </Stack>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            abgeben
          </Button>
        </Stack>
      </Dialog >


      <Grid sx={{ marginTop: 1 }} container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          {/* <Stack > */}

          <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
            bisherige Gebote
          </Typography>
          {biddingHistory.slice(0).reverse().map((bidding) => {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={3}
                key={bidding.index}
              >
                <Typography>{parseDate(bidding.change_at)?.format('DD.MM.YYYY')}</Typography>
                <Typography>{parseDate(bidding.start_at)?.format('DD.MM.YYYY')}</Typography>
                <Typography>{bidding.euros} €</Typography>
                <IconButton onClick={() => {
                  setSelectedBidding(bidding);
                  setOpenDeleteModal(true);
                }}>
                  <Delete />
                </IconButton>
              </Stack>
            );
          })
          }
          {/* </Stack> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
            Jahresbeiträge
          </Typography>
          {bidYears.slice(0).reverse().map((biddingYear) => {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={3}
                key={biddingYear.year}
              >
                <Typography>{biddingYear.year} ({biddingYear.months} Monate)</Typography>
                <Typography>{biddingYear.euros} €</Typography>
              </Stack>
            );
          })
          }
        </Grid>
      </Grid>

      <Dialog onClose={() => setOpenDeleteModal(false)} open={openDeleteModal}>
        <Stack sx={{ margin: "20px" }} justifyContent="center" alignItems="center" gap={2}>
          <Typography textAlign="center">
            Soll das Gebot <strong>{selectedBidding?.euros}€ vom {selectedBidding?.change_at}</strong> aus der Gebots-Historie entfernt werden?
          </Typography>
          <Button variant="contained" color="error" onClick={removeBidding}>
            Ja, entfernen.
          </Button>
        </Stack>
      </Dialog>
    </>
  )
}
