export default function moneyFormater(number: number): string {

  // Zahl in String konvertieren und Dezimalpunkt durch Komma ersetzen
  const formattedNumber = (number / 100).toFixed(2).toString().replace('.', ',');

  // Tausendertrennzeichen einfügen
  const parts = formattedNumber.split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Währungssymbol (optional) und formatierte Zahl zurückgeben
  return `${parts.join(',')} €`
}
