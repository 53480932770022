import React from "react";
import { Box, Stack, Tabs, Tab } from "@mui/material";
import dayjs from "dayjs";
import { AdminMembershipTabEditMembership } from "./AdminMembershipTabEditMembership";
import { AdminMembershipTabUsers } from "./AdminMembershipTabUsers";
import AdminMemberShipTabTransactions from "./AdminMemberShipTabTransactions";
import AdminMembershipTabBidding from "./AdminMembershipTabBidding";
import { UserInfos } from "../../types/UserInfos";
import { AdminMembershipOverview } from "./AdminMembershipOverview";
import { AdminMembershipHeader } from "./AdminMembershipHeader";
import { AdminMembershipShippingNode } from "./AdminMembershipShippingNode";
import * as isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import AdminMembershipTabDeposits from "./AdminMembershipTabDeposits";
dayjs.extend(isSameOrBefore)

export type AdminMembershipProps = {
  data: any;
}

export type FieldObject = {
  name: string;
  key: string;
  fieldType: "text" | "checkbox" | "largeText" | "select" | "date" | "boolean";
  subtle?: string;
  width?: number;
  options?: {
    name: string;
    value: string;
  }[]
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index.toString()}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index.toString() && (
        <Box sx={{ marginTop: 7 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const AdminMembership = ({ data }) => {

  const [userInfos, setUserInfos] = React.useState<UserInfos>(data.interaction.membership.value);
  const [tabvalue, setTabvalue] = React.useState("0");

  const usersOfMembership = data.interaction.membership_users.value;
  const allUsers = data.interaction.all_users.value;
  const deposits = data.interaction.deposits.value;
  const membershipId = data.interaction.membership.value.id;
  const biddingHistory = data.interaction.bids.value;
  const bidYears = data.interaction.bid_years.value;
  const financePrediction = data.interaction.membership.value.finance;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabvalue(newValue);
  };

  React.useEffect(() => {
    setUserInfos(data.interaction.membership.value)
  }, [data.interaction.membership.value])

  const handleChange = (key: string, data: any) => {
    setUserInfos((prev) => {
      return {
        ...prev,
        [key]: data
      }
    })
    // setUserInfos({
    //   ...userInfos,
    //   [key]: data,
    // })
  }


  return (<div>
    <AdminMembershipHeader userInfos={userInfos} />
    <Stack direction="row" alignItems="center" justifyContent="center" gap={10}>
      <Tabs value={tabvalue} onChange={handleChangeTab}>
        <Tab label="Übersicht" value={"1"} />
        <Tab label="Mitgliedschaft bearbeiten" value={"0"} />
        <Tab label="Benutzer" value={"2"} />
        <Tab label="Gebot" value={"3"} />
        <Tab label="Beiträge" value={"4"} />
        <Tab label="Einlage" value={"5"} />
        <Tab label="Verteilpunkt" value={"6"} />
      </Tabs>
    </Stack>

    <TabPanel value={tabvalue} index={0}>
      <AdminMembershipTabEditMembership userInfos={userInfos} handleChange={handleChange} />
    </TabPanel>
    <TabPanel value={tabvalue} index={1}>
      <AdminMembershipOverview financePrediction={financePrediction} />
    </TabPanel>
    <TabPanel value={tabvalue} index={2}>
      <AdminMembershipTabUsers membershipId={membershipId} usersOfMembership={usersOfMembership}/*  onSelect={() => console.log("onSelect")} */ allUsers={allUsers} />
    </TabPanel>
    <TabPanel value={tabvalue} index={3}>
      <AdminMembershipTabBidding userInfos={userInfos} biddingHistory={biddingHistory} bidYears={bidYears} />
    </TabPanel>
    <TabPanel value={tabvalue} index={4}>
      <AdminMemberShipTabTransactions data={data} />
    </TabPanel>
    <TabPanel value={tabvalue} index={5}>
      <AdminMembershipTabDeposits data={deposits} />
    </TabPanel>
    <TabPanel value={tabvalue} index={6}>
      <AdminMembershipShippingNode data={data} />
    </TabPanel>
    {/*    
    <Grid container columnSpacing={2}>
      {fields.map((field) => {
        return (
          <Grid key={`gridItem-${field.key}`} item xs={12} md={field.width ?? 6}>
            {FieldBuilder({myField: field})}
          </Grid>
        )
      })}
    </Grid> */}
  </div>);
}