import { useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import { Download, ExpandMore } from "@mui/icons-material";
import { sendToBackend } from "../helper/websocketHelper";
import { saveSvgAsPng } from "save-svg-as-png";
import { useNavigate, useLocation } from "react-router-dom";

function Bidding({
  bidding,
  burgerMenuOpen,
  setBurgerMenuOpen,
  menuOffset,
  setMenuOffset,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setMenuOffset(document.querySelector(".bidding")?.offsetHeight);
  }, [setMenuOffset, menuOffset, burgerMenuOpen]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    sendToBackend("bidding", {
      bid: data.get("bidding_input"),
    });
  };

  const handleClick = () => {
    if (burgerMenuOpen === 0) {
      setBurgerMenuOpen(2);
      navigate("/Bieteverfahren");
    }
    if (burgerMenuOpen === 2) setBurgerMenuOpen(0);
  };

  const getMoreInfo = () => {
    navigate("/Bieteverfahren");
  };

  // Styling
  const Wrapper = styled(Accordion)(() => ({
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 6,

    padding: "5px",

    [theme.breakpoints.up("lg")]: {
      padding: "10px 20px",
    },

    background: theme.palette.darkgreen,

    "&:first-of-type": {
      borderRadius: 0,
    },

    "& p": {
      color: theme.palette.white,
    },

    "& .MuiAccordionSummary-expandIconWrapper": {
      margin: "0 0 0 20px",
      color: theme.palette.white,
      transform: "scale(1)",
      transition: "transform 200ms ease-in-out",

      [theme.breakpoints.up("lg")]: {
        margin: 0,
      },

      "& > svg": {
        transition: "transform 200ms ease-in-out",
      },
    },

    "&.Mui-expanded": {
      margin: 0,

      "& .MuiAccordionSummary-expandIconWrapper > svg": {
        transform: "rotate(180deg)",
        [theme.breakpoints.up("lg")]: {
          transform: "rotate(180deg)",
        },
      },
    },

    "& .bidding__summary:hover": {
      ".MuiAccordionSummary-expandIconWrapper": {
        [theme.breakpoints.up("lg")]: {
          transform: "scale(2)",
        },
      },
    },

    "& .bidding": {
      "&__summary": {
        "& .MuiAccordionSummary-content": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          [theme.breakpoints.up("lg")]: {
            alignItems: "end",
            gap: 50,
            flexDirection: "row",
          },
        },

        "&__text": {
          textAlign: "center",
          fontSize: "1rem",

          [theme.breakpoints.up("sm")]: {
            fontSize: "1.25rem",
          },
        },

        "& p": {
          display: "none",
          margin: "0 10px",

          [theme.breakpoints.up("lg")]: {
            display: "1.25rem",
          },
        },
        "&.MuiTypography-root": {
          color: theme.palette.black,
        },
      },

      "&__details": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",

        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },

        "&__inner": {
          flexDirection: "column",
          gap: 20,

          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            justifyContent: "center",
            gap: "100px",
          },

          "&__box": {
            flexDirection: "column",
            alignItems: "center",
            gap: 10,

            [theme.breakpoints.up("sm")]: {
              flexDirection: `${bidding.value.admin ? "column" : "row"}`,
              justifyContent: `${bidding.value.admin ? "space-evenly" : "center"
                }`,
              gap: `${bidding.value.admin ? "30px" : "50px"}`,
            },

            "&__text": {
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
              marginTop: 20,

              [theme.breakpoints.up("sm")]: {
                alignItems: "start",
                gap: 0,
                marginTop: 0,
              },

              "&__mobile": {
                display: "block",
                textAlign: "center",

                [theme.breakpoints.up("sm")]: {
                  textAlign: "left",
                },

                [theme.breakpoints.up("lg")]: {
                  display: "none",
                },
              },
            },
          },
          "&__imagecontainer": {
            justifyContent: "center",
          },
        },

        "&__input": {
          "& .MuiOutlinedInput-input": {
            color: theme.palette.white,
            width: "70px",
          },
          "& .MuiInputLabel-root": {
            color: theme.palette.white,
          },
          "& input:valid + fieldset": {
            borderColor: theme.palette.white,
          },
          "& input:invalid + fieldset": {
            borderColor: "red",
            borderWidth: 2,
          },
        },

        ".poloto": {
          width: "80vw",
          height: "auto",

          [theme.breakpoints.up("sm")]: {
            width: "40vw",
          },

          [theme.breakpoints.up("lg")]: {
            width: "unset",
            height: "unset",
          },
        },
      },
    },
  }));

  return (
    <>
      {bidding.value && (
        <Wrapper className="bidding" expanded={burgerMenuOpen === 2}>
          <AccordionSummary
            className="bidding__summary"
            expandIcon={<ExpandMore />}
            onClick={handleClick}
          >
            <Typography
              variant="h4"
              color="white"
              className="bidding__summary__text"
            >
              {bidding.value.title}
            </Typography>
            {bidding.value.bid && (
              <Box>
                <Typography>{bidding.value.bid.title}</Typography>
              </Box>
            )}
          </AccordionSummary>
          <AccordionDetails className="bidding__details">
            <Stack className="bidding__details__inner">
              <Stack className="bidding__details__inner__box">
                {bidding.value.bid && (
                  <Stack
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    direction="row"
                    alignItems="center"
                    gap="10px"
                    autoComplete="off"
                  >
                    <TextField
                      className="bidding__details__input"
                      margin="none"
                      id="bidding_input"
                      label={bidding.value.bid.bid_label}
                      name="bidding_input"
                      size="small"
                      autoFocus
                      color="secondary"
                      onChange={(value) => Math.round(value)}
                      type="number"
                      defaultValue={bidding.value.bid.euros}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      className="bidding__details__button"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      abgeben
                    </Button>
                  </Stack>
                )}
                <Stack
                  direction="column"
                  justifyContent="center"
                  gap={bidding.value.admin ? "20px" : "0"}
                >
                  <Stack className="bidding__details__inner__box__text">
                    {bidding.value.bid && (
                      <Typography>{bidding.value.bid.shares}</Typography>
                    )}
                    <Typography>Abgabe bis {bidding.value.end_at}</Typography>
                    {!bidding.value.admin && (
                      <>
                        <Typography className="bidding__details__inner__box__text__mobile">
                          {bidding.value.bid.title}
                        </Typography>
                      </>
                    )}
                  </Stack>
                  {bidding.value.admin && (
                    <>
                      <Stack className="bidding__details__inner__box__text">
                        <Typography>
                          {bidding.value.admin.bidding_shares}
                        </Typography>
                        <Typography>
                          {bidding.value.admin.bidding_members}
                        </Typography>
                        <Typography>{bidding.value.admin.average}</Typography>
                        <Typography>
                          {bidding.value.admin.extrapolated}
                        </Typography>
                      </Stack>

                      <Button
                        href={bidding.value.admin.csv}
                        startIcon={<Download />}
                        variant="outlined"
                        size="large"
                        color="secondary"
                      >
                        Gebote.csv
                      </Button>
                      <Button
                        onClick={() =>
                          saveSvgAsPng(
                            document.getElementsByClassName("poloto")[0],
                            "Gebotsverteilung.png"
                          )
                        }
                        startIcon={<Download />}
                        size="large"
                        variant="outlined"
                        color="secondary"
                      >
                        Gebotsverteilung.png
                      </Button>
                    </>
                  )}
                </Stack>
              </Stack>
              {location.pathname !== "/Bieteverfahren" &&
                location.pathname !== "/" && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={getMoreInfo}
                  >
                    mehr info
                  </Button>
                )}
              {bidding.value.admin && (
                <Stack
                  className="bidding_details_inner_imagecontainer"
                  alignItems="center"
                  dangerouslySetInnerHTML={{
                    __html: bidding.value.admin.distribution_plot,
                  }}
                ></Stack>
              )}
            </Stack>
          </AccordionDetails>
        </Wrapper>
      )}
    </>
  );
}

export default Bidding;
