import React, { useState } from 'react'
import { Grid, Button, Stack, Dialog, Typography } from '@mui/material'
import { FieldBuilder } from '../FieldBuilder';
import { sendToBackend } from '../../helper/websocketHelper';
import { FieldObject } from '../../types/FieldObject';
import { Delete } from '@mui/icons-material';

const fields: FieldObject[] = [
    {
        name: "Vorname",
        key: "name",
        fieldType: "text",
    },
    {
        name: "Nachname",
        key: "surname",
        fieldType: "text",
    },
    {
        name: "Stadt",
        key: "city",
        fieldType: "text",
    }, {
        name: "Straße",
        key: "street",
        fieldType: "text",
    }, {
        name: "Eintrittsdatum",
        key: "created_at",
        fieldType: "date",
    }, {
        name: "Startdatum",
        key: "start_at",
        fieldType: "date",
    }, {
        name: "Enddatum",
        key: "end_at",
        fieldType: "date",
    }, {
        name: "Anteil",
        key: "shares",
        fieldType: "select",
        options: [
            {
                name: "Ganzer",
                value: "One"
            },
            {
                name: "Halber",
                value: "Half"
            },
            {
                name: "Eineinhalb",
                value: "OneAndHalf"
            },
            {
                name: "Doppelt",
                value: "Two"
            }
        ],
        disabled: (userInfos) => {
            return userInfos["subscription"] === "NoVegetables";
        },
    },
    {
        name: "Mitgliedschaftsart",
        key: "subscription",
        fieldType: "select",
        options: [
            {
                name: "Warteliste",
                value: "WaitingList"
            },
            {
                name: "Passiv",
                value: "NoVegetables"
            },
            {
                name: "Aktiv",
                value: "Active"
            },
            {
                name: "Team",
                value: "Team"
            },
            {
                name: "Umsonstanteil",
                value: "FreeVegetables"
            },
            {
                name: "Probe",
                value: "TestMembership"
            }
        ]
    },
    {
        name: "Notizen",
        key: "notes",
        fieldType: "largeText",
        width: 12
    }]

export function AdminMembershipTabEditMembership({ handleChange, userInfos }) {
    const [openOverlay, setOpenOverlay] = useState(false);
    const submit = () => {
        sendToBackend("save_membership", {
            ...userInfos,
        });
    }

    const removeMembership = () => {
        sendToBackend("remove_membership")
        setOpenOverlay(false)
    }

    return (
        <Grid container columnSpacing={2}>
            {fields.map((field) => {
                return (
                    <Grid key={`gridItem-${field.key}`} item xs={12} md={field.width ?? 6}>
                        {/* <FieldBuilder key={`fieldBuilder-${field.key}`} myField={field} /> */}
                        {FieldBuilder({ myField: field }, handleChange, userInfos)}
                    </Grid>
                )
            })}
            <Stack justifyContent="space-between" direction="row" sx={{ marginLeft: 2, width: "100%" }}>
                <Button sx={{
                    marginTop: 2,
                }} onClick={() => submit()} variant="contained" color="primary">Speichern</Button>
                <Button sx={{
                    marginTop: 2,
                }} onClick={() => setOpenOverlay(true)} variant="contained" startIcon={<Delete />} color="error">Mitgliedschaft löschen</Button>
            </Stack>
            <Dialog onClose={() => setOpenOverlay(false)} open={openOverlay}>
                <Stack sx={{ margin: "20px" }} justifyContent="center" alignItems="center" gap={2}>
                    <Typography textAlign="center">
                        Soll diese Mitgliedschaft von <strong>{userInfos?.name} {userInfos?.surname}</strong> endgültig gelöscht werden?
                    </Typography>
                    <Stack direction="row" gap={2}>
                        <Button variant="contained" onClick={() => setOpenOverlay(false)}>zurück</Button>
                        <Button variant="contained" color="error" onClick={removeMembership}>
                            Ja, löschen!
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        </Grid>
    )
}
