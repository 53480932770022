
import { Button, Dialog, MenuItem, Select, Typography } from "@mui/material"
import TimelineItem from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box, Stack } from "@mui/system"
import React, { useEffect } from "react"
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { MembershipVP, MemberState, Verteilpunkt } from "../../types/Verteilpunkt"
import { ChangeCircle, Delete, Grade, HourglassBottom, LocationOn } from "@mui/icons-material";
import { sendToBackend } from "../../helper/websocketHelper";
import { parseDate } from "../../helper/dateHelper";

export type AdminMembershipShippingNodeProps = {
    data: any
}

export const AdminMembershipShippingNode = ({ data }: AdminMembershipShippingNodeProps) => {
    const [vps, setVps] = React.useState<Verteilpunkt[]>([])
    const [myVpHistory, setMyVpHistory] = React.useState<MembershipVP[]>([])
    const [selectedVpHist, setSelectedVpHist] = React.useState<MembershipVP | null>(null)
    const [selectedVpFromList, setSelectedVpFromList] = React.useState<Verteilpunkt | null>(null)
    useEffect(() => {
        if (!data || !data.interaction || !data.interaction.all_vps || !data.interaction.all_vps.value) {
            // console.log("no data", data.interaction.all_vps.value)
            return
        }
        const parsedVps = Verteilpunkt.fromJsonArray(data.interaction.all_vps.value)
        setVps(parsedVps.sort((a, b) => a.code.localeCompare(b.code)))
    }, [data.interaction.all_vps.value, data])

    useEffect(() => {
        if (!data || !data.interaction || !data.interaction.membership_vps || !data.interaction.membership_vps.value) return
        const parsedMemberVps = MembershipVP.fromJsonArray(data.interaction.membership_vps.value)
        // console.log("parsedMemberVps", parsedMemberVps)
        setMyVpHistory(parsedMemberVps)

    }, [data.interaction.membership_vps.value, data])

    const getVPById = (id: number) => {
        return vps.find(vp => vp.id === id) || new Verteilpunkt(
            -1,
            "Unbekannt",
        )
    }

    const getStateDisplay = (state: MemberState | undefined) => {
        let stateText: JSX.Element | string = ""
        let icon: JSX.Element | null = null
        if (state === undefined || state.None) {
            stateText = "Nicht zugeordnet"
            icon = <Grade />
        } else if (state.Member) {
            const vp = getVPById(state.Member.member_id)
            stateText = <Typography>
                Mitglied am VP {vp?.code}
            </Typography>
            icon = <LocationOn />
        } else if (state.Waitinglist) {
            icon = <HourglassBottom />
            const vp = getVPById(state.Waitinglist.waiting_id)
            stateText = <Typography>
                Auf Warteliste für VP {vp?.code}
            </Typography>
        } else if (state.MemberWaitinglist) {
            icon = <ChangeCircle />
            const vp = getVPById(state.MemberWaitinglist.waiting_id)
            stateText = <Typography>
                auf Warteliste zum Wechsel zum VP {vp?.code}
            </Typography>
        }

        return { stateText, icon }
    }

    const deleteVPHistoryEntry = (id: number) => {
        sendToBackend("remove_vp_membership", { index: id })
        setSelectedVpHist(null)
    }

    const createNewHistEntry = (data: any) => {
        sendToBackend("add_vp_membership", { value: data })
    }


    const AddToWaitlist = ({ latestVP }: { latestVP: MembershipVP | undefined }) => {
        if (latestVP !== undefined && (latestVP.state.Member?.member_id === selectedVpFromList?.id || latestVP?.state.MemberWaitinglist?.member_id === selectedVpFromList?.id || latestVP?.state.MemberWaitinglist?.waiting_id === selectedVpFromList?.id || latestVP?.state.Waitinglist?.waiting_id === selectedVpFromList?.id)) {
            return (<></>);
        }

        return (
            <Button onClick={() => {
                if (latestVP !== undefined && latestVP.state.Member) {
                    createNewHistEntry({
                        MemberWaitinglist: {
                            waiting_id: selectedVpFromList?.id,
                            member_id: latestVP?.state.Member.member_id
                        }
                    })
                } else {
                    createNewHistEntry({
                        Waitinglist: {
                            waiting_id: selectedVpFromList?.id
                        }
                    })
                }
            }}> {`Auf Warteliste für VP ${selectedVpFromList?.code ?? ""} eintragen`}</Button >
        )
    }

    const AssignToVp = ({ latestVP }: { latestVP: MembershipVP | undefined }) => {
        if (latestVP !== undefined && (latestVP.state.Member?.member_id === selectedVpFromList?.id || latestVP?.state.MemberWaitinglist?.member_id === selectedVpFromList?.id || latestVP?.state.MemberWaitinglist?.waiting_id === selectedVpFromList?.id || latestVP?.state.Waitinglist?.waiting_id === selectedVpFromList?.id)) {
            return (<></>);
        }

        return (
            <Button onClick={() => {
                if (latestVP !== undefined && latestVP.state.Waitinglist) {
                    createNewHistEntry({
                        MemberWaitinglist: {
                            waiting_id: latestVP?.state.Waitinglist.waiting_id,
                            member_id: selectedVpFromList?.id
                        }
                    })
                } else {
                    createNewHistEntry({
                        Member: {
                            member_id: selectedVpFromList?.id
                        }
                    })
                }
            }}>Zu VP {selectedVpFromList?.code ?? ""} zuordnen</Button>
        )
    }

    const AssignToVpFromWaitinglist = ({ vp }: { vp: Verteilpunkt }) => {
        return (
            <Button color="info" onClick={() => {
                createNewHistEntry({
                    Member: {
                        member_id: vp.id
                    }
                })
            }}>Wartelistenplatz für VP {vp.code} genehmigen</Button>
        )
    }

    const RemoveFromVp = ({ vp, latestVp }: { vp: Verteilpunkt, latestVp: MembershipVP }) => {
        return (
            <Button color="error" onClick={() => {
                if (latestVp.state.MemberWaitinglist) {
                    createNewHistEntry({
                        Waitinglist: {
                            waiting_id: latestVp?.state.MemberWaitinglist.waiting_id,
                        }
                    })
                } else {
                    createNewHistEntry("None")
                }
            }}>Bei VP {vp.code} austragen</Button>
        )
    }

    const RemoveFromWaitinglist = ({ vp, latestVp }: { vp: Verteilpunkt, latestVp: MembershipVP }) => {
        return (
            <Button color="error" onClick={() => {
                if (latestVp.state.MemberWaitinglist) {
                    createNewHistEntry({
                        Member: {
                            member_id: latestVp?.state.MemberWaitinglist.member_id,
                        }
                    })
                } else {
                    createNewHistEntry("None")
                }
            }}>Aus Warteliste für VP {vp.code} austragen</Button>
        )
    }

    const ActionButtons = () => {
        //find the last state by index
        const lastState = myVpHistory.sort((a, b) => b.index - a.index)[0]
        // console.log("lastState", lastState)
        if (lastState?.state?.Member) {
            return (
                <>
                    <AddToWaitlist latestVP={lastState} />
                    <AssignToVp latestVP={lastState} />
                    <RemoveFromVp latestVp={lastState} vp={getVPById(lastState.state.Member.member_id)} />
                </>
            )
        } else if (lastState?.state?.Waitinglist) {
            return (
                <>
                    <AddToWaitlist latestVP={lastState} />
                    <AssignToVp latestVP={lastState} />
                    <AssignToVpFromWaitinglist vp={getVPById(lastState.state.Waitinglist.waiting_id)} />
                    <RemoveFromWaitinglist latestVp={lastState} vp={getVPById(lastState.state.Waitinglist.waiting_id)} />
                </>
            )
        } else if (lastState?.state?.MemberWaitinglist) {
            return (
                <>
                    <AddToWaitlist latestVP={lastState} />
                    <AssignToVp latestVP={lastState} />
                    <AssignToVpFromWaitinglist vp={getVPById(lastState.state.MemberWaitinglist.waiting_id)} />
                    <RemoveFromWaitinglist latestVp={lastState} vp={getVPById(lastState.state.MemberWaitinglist.waiting_id)} />
                </>
            )
        } else {
            return (
                <>
                    <AddToWaitlist latestVP={lastState} />
                    <AssignToVp latestVP={lastState} />
                </>
            )
        }
    }

    return (
        <Box>
            <Dialog onClose={() => setSelectedVpHist(null)} open={selectedVpHist !== null}>
                <Stack sx={{ margin: "20px" }} justifyContent="center" alignItems="center" gap={2}>
                    <Typography textAlign="center">
                        Soll der Eintrag vom {parseDate(selectedVpHist?.change_at)?.format('DD.MM.YYYY')} <b>{getStateDisplay(selectedVpHist?.state).stateText}</b> wirklich entfernt werden?
                    </Typography>
                    <Button variant="contained" onClick={() => deleteVPHistoryEntry(selectedVpHist?.index ?? -1)}>
                        Ja, entfernen.
                    </Button>
                </Stack>
            </Dialog>
            <Stack direction={"row"} spacing={2}>
                <Select
                    value={selectedVpFromList?.id}
                    onChange={(e) => {
                        if (!e.target.value || typeof e.target.value === "string") return

                        const selectedVp = vps.find(vp => vp.id === e.target.value)
                        if (!selectedVp) return

                        setSelectedVpFromList(selectedVp)
                    }}
                    sx={{ width: 300 }}
                >
                    {vps.map(vp => {
                        return <MenuItem value={vp.id}>{vp.code}</MenuItem>
                    })}
                </Select>
                {ActionButtons()}
            </Stack>

            <Timeline sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}>
                {myVpHistory.sort((a, b) => b.index - a.index).map((vp: MembershipVP) => {
                    const { stateText, icon } = getStateDisplay(vp.state)
                    return (
                        <TimelineItem>
                            <TimelineOppositeContent>{parseDate(vp.change_at)?.format('DD.MM.YYYY')} </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot>
                                    {icon}
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>

                            <TimelineContent>
                                <Stack sx={{ mt: 1 }} direction={"row"}>
                                    {stateText}
                                    <Button size="small" onClick={() => {
                                        setSelectedVpHist(vp)
                                    }}><Delete fontSize="small" /></Button>
                                </Stack>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
            </Timeline>

        </Box>
    )
}