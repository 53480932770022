import { Line } from "@ant-design/plots"
import { Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import dayjs, { Dayjs } from "dayjs"
import React from "react"
import { useEffect, useState } from "react"
import { Membership, SubscriptionType } from "../../types/Membership"
import { Person, TrendingDown, TrendingUp } from "@mui/icons-material"

export type AdminMembershipTimeLineProps = {
    data: Membership[]
    setSelectedDate: (date: Dayjs) => void
    selectedDate: Dayjs | undefined
}

export type MembershipDataPoint = {
    date: Dayjs
    value: number
}
let selectedDateFromClick: string | undefined = undefined;

export const AdminMembershipTimeLine = ({ data, setSelectedDate, selectedDate }: AdminMembershipTimeLineProps) => {
    const [accMembers, setAccMembers] = useState<{
        date: Dayjs;
        value: number;
    }[]>([])
    const [sliderValue, setSliderValue] = useState<number>(0)
    useEffect(() => {
        if (!data) {
            setAccMembers([]);
            return;
        }
        const accData = getAccumulatedMemberships(data)
        setAccMembers(accData)
        if (accData.length > 0) {
            const today = dayjs().startOf('day');
            //console.log("today", today);
            // console.log(accData);
            const date = accData.find(data => data.date.isSame(today))?.date;
            // console.log("date", date);
            if (date) {
                setSelectedDate(date)
            }
        }
    }, [data])


    useEffect(() => {
        if (!selectedDate) return
        // find the index of the selected date
        const index = accMembers.findIndex((a) => a.date.isSame(selectedDate))
        if (index === -1) return
        setSliderValue(index)
    }, [selectedDate, accMembers])

    const getAccumulatedMemberships = (members: Membership[]) => {
        const membersWithVegetables = members.filter((m: Membership) => m.subscription !== SubscriptionType.NoVegetables && m.subscription !== SubscriptionType.WaitingList);
        const days = [];
        const today = dayjs().startOf('day');

        //iterate for each day between now - 3 months and now + 6 months
        for (let i = -90; i < 180; i++) {
            const date = today.add(i, 'day')

            const value = membersWithVegetables.filter((m: Membership) => {
                const start = m.start_at
                const end = m.end_at

                return (start <= date && (end == null || end >= date));
            }).length

            days.push({ date, value });
        }

        //remove all days wich have no difference to the previous day
        for (let i = 0; i < days.length; i++) {
            const current = days[i]
            const next = days[i + 1]
            if (next && current.value === next.value && !current.date.isSame(today)) {
                days.splice(i, 1)
                i--
            }
        }
        return days

    }

    const getMiniumumAmountOfMemberships = () => {
        return Math.min(...accMembers.map((a) => a.value))
    }


    const accMembersString = accMembers.map(member => { return { date: member.date.format("DD.MM.YYYY"), value: member.value } });

    return (
        <Box sx={{ mb: 2, mt: 2 }}>
            <Line

                onReady={(plot) => {

                    plot.on('plot:click', (e: any) => {
                        if (!selectedDateFromClick) return;
                        setSelectedDate(dayjs(selectedDateFromClick, 'DD.MM.YYYY'))
                    })
                    plot.on("tooltip:change", (e: any) => {
                        if (e.data.items && e.data.items.length === 0) return
                        selectedDateFromClick = e.data.items[0].title
                    })
                }}
                height={200}
                data={accMembersString}
                padding="auto"
                xField={'date'}
                yField={'value'}
                xAxis={{ tickCount: 5, }}
                yAxis={{
                    min: getMiniumumAmountOfMemberships()
                }}
                animation={false}
                tooltip={{
                    // title: 'Datum',
                    customContent(title, data) {
                        let amount = "?"
                        let deltaMembers = 0
                        if (data && data.length > 0) {
                            amount = data[0].value

                            const date = data[0].data.date
                            const index = accMembersString.findIndex((a) => a.date === date)
                            if (index === -1) return
                            if (index - 1 < 0) return
                            deltaMembers = accMembersString[index].value - accMembersString[index - 1].value
                        }
                        return <>
                            <Stack direction={"row"}>
                                <Person fontSize="small" />
                                <Typography variant="body2">{amount}</Typography>
                            </Stack>

                            <Stack direction={"row"} sx={{
                                color: deltaMembers > 0 ? 'green' : 'red',
                            }}>
                                {deltaMembers >= 0 ? <TrendingUp fontSize="small" /> : <TrendingDown fontSize="small" />}
                                <Typography variant="body2">{deltaMembers > 0 ? '+' : ''}{deltaMembers}</Typography>
                            </Stack>
                        </>
                    },
                }}
                annotations={[
                    {
                        type: 'region',
                        start: [0, "min"],
                        end: [sliderValue, "max"],
                    },
                    {
                        type: 'dataMarker',
                        text: {
                            content: '',
                            style: {
                                textAlign: 'left',
                            },
                        },
                        line: {
                            length: 20,
                        },
                        style: {
                            textAlign: 'center',
                            fill: 'rgba(0,0,0,0.85)',
                        },
                        point: {
                            style: {
                                fill: '#f5222d',
                                stroke: '#f5222d',
                            },
                        },
                        position: accMembersString[sliderValue],
                    }
                ]}

            />
        </Box>
    )
}