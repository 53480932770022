import { Typography, Box, Divider,useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import ImageModal from "./modules/ImageModal";
import DownloadIcon from "@mui/icons-material/Download";
import { url } from "../helper/websocketHelper";
import Editor from "./modules/Editor";
import DocumentHeader from "./DocumentHeader";
import ImageSlider from "./modules/ImageSlider";

function Document({ data }) {
  const document = data.interaction.document;
  const showAside = document.images?.length + document.files?.length
  const tablet = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <MainContent>
        <DocumentHeader
          title={document.title}
          topic={document.topic}
          created_at={document.created_at}
          created_by={document.created_by}
          body={document.body}
          id={document.id}
        />
        <ContentBox>
          <Editor body={document.body} />
          {showAside > 0 && (
            <Aside>
              {tablet && document.images.length > 0 && (
                <>
                  <Typography variant="h5" sx={{ margin: "20px" }}>
                    Bilder
                  </Typography>
                  {document.images.map((image, index) => {
                    // TODO: image link muss hier noch korrigiert werden
                    return (
                      <ImageModal
                        key={index}
                        image={image}
                        index={index}
                        images={document.images}
                      />
                    );
                  })}
                </>
              )}
              {!tablet && document.images.length > 0 && (
                <ImageSlider images={document.images}/>
              )}
              {document.images.length > 0 && document.files.length > 0 && (
                <Divider sx={{ color: theme.palette.darkgreen }} />
              )}
              {document.files.length > 0 && (
                <>
                  <Typography variant="h5" className="headline">
                    Dateien
                  </Typography>
                  {document.files.map((file, index) => {
                    return (
                      <FileDownload key={index} href={`${url}${file.path}`}>
                        <DownloadIcon />
                        <p>{file.name}</p>
                      </FileDownload>
                    );
                  })}
                </>
              )}
            </Aside>
          )}
        </ContentBox>
      </MainContent>
    </>
  );
}

export default Document;

// Styling

const MainContent = styled(Box)(() => ({
  marginBottom: 80,
  [theme.breakpoints.up("md")]: {
    marginBottom: 100,
  },
}));

const ContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column-reverse",

  "& .remirror-theme": {
    width: "100%",
  },

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    
    "& .remirror-theme": {
      width: document.images?.length || document.files?.length ? "75%" : "100%",
      flex: "1 1 100%",
    },
  },
}));

const FileDownload = styled("a")(() => ({
  color: theme.palette.black,
  display: "flex",
  alignItems: "center",
  gap: 20,
  background: theme.palette.lightgreen,
  padding: 20,
  marginBottom: 20,
  textDecorationColor: "transparent",
  transition: "text-decoration-color 350ms ease-in-out",

  "& p": {
    wordBreak: "break-all",
    overflowWrap: "break-word",
    textWrap: "wrap",
  },
  "& svg": {
    transform: "scale(1)",
    transition: "transform 350ms ease",
  },
  "&:hover": {
    transition: "text-decoration-color 350ms ease-in-out",
    textDecorationColor: theme.palette.black,
    "& svg": {
      transform: "scale(1.5)",
      transition: "transform 350ms ease",
    },
  },
}));

const Aside = styled(Box)(() => ({
  boxSizing: "border-box",
  width: "100%",
  padding: "0 10px",

  "& h5": {
    display: "none",
  },

  [theme.breakpoints.up("md")]: {
    width: "25%",
    padding: "0 0 0 30px",
    flex: "1 0 25%",

    "& h5": {
      display: "block",
      margin: "20px",
    },
  },
}));
