import { useState, useEffect } from "react";
import { Typography, Box, Divider } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import ImageModal from "./modules/ImageModal";
import { url } from "../helper/websocketHelper";
import Comments from "./modules/Comments";
import DocumentHeader from "./DocumentHeader";
import Editor from "./modules/Editor";
import DownloadIcon from "@mui/icons-material/Download";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { CalendarMonth } from "@mui/icons-material";

function Event({ data }) {
  const document = data.interaction.event.value;

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const arr = locations;
    Object.values(document.location).forEach((e) => arr.push(e));
    const uniqueArray = arr.filter(function (item, pos) {
      return arr.indexOf(item) === pos;
    });
    setLocations(uniqueArray);
  }, [document]);

  return (
    <>
      <MainContent>
        <DocumentHeader
          title={document.title}
          topic={document.topic}
          created_at={document.created_at}
          end_at={document.end_at}
          start_at={document.start_at}
          created_by={document.created_by}
          body={document.body}
          id={document.id}
          location={document.location}
          category={document.category}
        />
        <ContentBox>
          {document.description && <Editor body={document.description} />}
          {document.template_body && <Editor body={document.template_body} />}
          <Aside>
            {document.images.length > 1 && (
              <>
                <Typography variant="h5" sx={{ margin: "20px" }}>
                  Bilder
                </Typography>
                {document.images.map((image, index) => {
                  // TODO: image link muss hier noch korrigiert werden
                  return (
                    <ImageModal
                      key={index}
                      image={image}
                      index={index}
                      stretch
                    />
                  );
                })}
              </>
            )}
            {document.images.length > 1 && document.files.length > 1 && (
              <Divider sx={{ color: theme.palette.darkgreen }} />
            )}
            {document.files.length > 1 && (
              <>
                <Typography variant="h5" sx={{ margin: "20px" }}>
                  Dateien
                </Typography>
                {document.files.map((file, index) => {
                  return (
                    <FileDownload key={index} href={`${url}${file.path}`}>
                      <DownloadIcon />
                      {file.name}
                    </FileDownload>
                  );
                })}
              </>
            )}
          </Aside>
        </ContentBox>
        <MainFacts>
          <InfoHeadline variant="h4">Wichtige Infos zum Event:</InfoHeadline>
          <InnerFacts>
            {document.start_at && (
              <>
              <FlexBox>
                  <CalendarMonth />
                  {
                    <Typography>
                      {document.start_at
                        ? new Date(document.start_at).toLocaleDateString(
                            "de-DE",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )
                        : ""}
                    </Typography>
                  }
                  {document.end_at && document.start_at !== document.end_at && (
                    <Typography>
                      bis{" "}
                      {document.end_at
                        ? new Date(document.end_at).toLocaleDateString(
                            "de-DE",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )
                        : ""}
                    </Typography>
                  )}
                </FlexBox>
                <FlexBox>
                  <AccessTimeOutlinedIcon />
                  {document.start_at && (
                    <Typography>
                      {new Date(document.start_at).toLocaleTimeString("de-DE", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Typography>
                  )}
              </FlexBox>
              </>
            )}
            {locations.length > 0 && (
              <FlexBox>
                <LocationOnOutlinedIcon />
                <Typography>{locations.map((e) => e)}</Typography>
              </FlexBox>
            )}
            {document.takealong && (
              <FlexBox>
                <LightbulbOutlinedIcon />
                <Editor body={document.takealong} />
              </FlexBox>
            )}
          </InnerFacts>
        </MainFacts>
      </MainContent>
      {document.comments.length !== 0 && <Comments data={document.comments} />}
    </>
  );
}

export default Event;

const MainContent = styled(Box)(() => ({
  marginBottom: 80,
  [theme.breakpoints.up("md")]: {
    marginBottom: 100,
  },
}));


const InnerFacts = styled(Box)(() => ({
  paddingLeft: "20px",

  [theme.breakpoints.up("md")]: {
    paddingLeft: "60px",
  },
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "top",
  gap: 20,
  marginTop: 10,
}));

const InfoHeadline = styled(Typography)(() => ({
  fontWeight: 600,
  padding: 20,
}));

const MainFacts = styled(Box)(() => ({
  wordBreak: "break-word",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 10px 30px",
  margin: "20px 30px 20px -16px",
  background: theme.palette.yellow,
  width: "100%",

  [theme.breakpoints.up("md")]: {
    padding: "20px 30px 30px",
    margin: "20px 0",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  "& > div": {
    paddingRight: "20px",

    [theme.breakpoints.up("md")]: {
      paddingRight: "50px",
    },

    "& p, a": {
      maxWidth: "calc(100% - 10px)",
      margin: 0,
      padding: 0,
      hyphens: "auto",
      wordBreak: "break-word",
      textWrap: "wrap",
    },
  },
}));

const ContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const FileDownload = styled("a")(() => ({
  color: theme.palette.black,
  display: "flex",
  alignItems: "center",
  gap: 20,
  background: theme.palette.lightgreen,
  padding: 20,
  marginBottom: 20,
  textDecorationColor: "transparent",
  transition: "text-decoration-color 350ms ease-in-out",
  wordBreak: "break-all",
  "& svg": {
    transform: "scale(1)",
    transition: "transform 350ms ease",
  },
  "&:hover": {
    transition: "text-decoration-color 350ms ease-in-out",
    textDecorationColor: theme.palette.black,
    "& svg": {
      transform: "scale(1.5)",
      transition: "transform 350ms ease",
    },
  },
}));

const Aside = styled(Box)(() => ({
  boxSizing: "border-box",
  width: "100%",
  padding: "0 25px 0 10px",
  [theme.breakpoints.up("md")]: {
    width: "30%",
    padding: "0 30px",
  },
}));
