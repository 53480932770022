import {useEffect} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter, useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { history } from "./BrowserHistory"


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={5}>
      <BrowserRouter history={history}>
      <ScrollToTop />
        <App />
      </BrowserRouter>
    </SnackbarProvider>
  </ThemeProvider>
  // </React.StrictMode>
);
