import { Stack, Box, Typography, Button, Dialog, TextField, IconButton, Snackbar } from "@mui/material";
import { useState } from "react";
import styled from "@emotion/styled";
import theme from "../theme";
import Info from "@mui/icons-material/Info";
import { sendToBackend } from "../helper/websocketHelper";
import { Close } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import CopyToClipboard from "react-copy-to-clipboard";

function Membership({ data }) {
  const texts = data.interaction.texts.value;
  const document = data.interaction.membership.value;
  console.log(document)

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const [openPopup, setOpenPopup] = useState(false);

  const [newUserData, setNewUserData] = useState({
    name: document.name,
    surname: document.surname,
    street: document.street,
    zip: document.zip,
    city: document.city
  })

  const Wrapper = styled(Box)(() => ({
    padding: "0 20px",

    [theme.breakpoints.up("lg")]: {
      padding: "20px 0",
    },

    " .MuiTypography-body1": {
      marginLeft: 20,
    },

    ".membership": {
      background: "red",
      "&__headline": {
        margin: "30px 0 5px",
      },
      "&__box": {
        margin: "10px 0",
      },
      "&__flexbox": {
        flexDirection: "row",
        gap: 30,
        flexWrap: "wrap",
        [theme.breakpoints.up("md")]: {
          gap: 100,
        },
      },
    },
  }));

  const myShare = (share) => {
    switch (share) {
      case "Half": return "0,5"
      case "One": return "1"
      case "OneAndHalf": return "1,5"
      case "Two": return "2"
      default: return "-"
    }
  }

  return (
    <>
      <Wrapper className="membership">
        {document.id && (
          <>
            <Typography variant="h3" className="membership__headline">
              {texts.id}: {document.id}{" "}
              {document.main_user_name &&
                ` - ${texts.main_person}: ${document.main_user_name}`}
            </Typography>
            <Stack direction="row" gap={2} padding={2}>
              <Info />
              <Typography>{texts.invoice_help}</Typography>
            </Stack>
          </>
        )}
        <Stack className="membership__flexbox">
          <Box>
            <Typography variant="h4" className="membership__headline">
              {texts.contact}
            </Typography>
            <Typography className="membership__bodytext">
              {document.name} {document.surname}
            </Typography>
            <Typography className="membership__bodytext">
              {document.street}
            </Typography>
            <Typography className="membership__bodytext">
              {document.zip} {document.city}
            </Typography>
            <Button color="primary" variant="contained" sx={{ margin: "10px 0 0 0" }} onClick={() => setOpenPopup(true)} >
              {texts.change_my_date}
            </Button>
          </Box>
          <Box>
            <Box className="membership__box">
              <Typography variant="h4" className="membership__headline">
                {texts.membership}
              </Typography>
              {document.created_at && (
                <Typography>{texts.created_at} {new Date(document.created_at).toLocaleDateString('de-DE', {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}</Typography>
              )}
              {document.start_at && (
                <Typography>{texts.started_at} {new Date(document.start_at).toLocaleDateString('de-DE', {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}</Typography>
              )}
              {document.end_at && (
                <Typography>{texts.end_at} {new Date(document.endet_at).toLocaleDateString('de-DE', {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}</Typography>
              )}
            </Box>
            <Typography>{texts.type}: {texts.subscription}</Typography>
            <Typography>{texts.shares}: {myShare(document.shares)}</Typography>
            <Typography>{texts.current_bid}</Typography>
            {document.vp_path && document.vp_title && (
              <Typography>
                {texts.distribution_point}:
                <a href={document.vp_path}>
                  {document.vp_title}
                  {document.vp_code && " (" + document.vp_code + ")"}
                </a>
              </Typography>
            )}
          </Box>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
            {document.extra_user_names && document.extra_user_names.length > 0 && (
              <>
                <Typography variant="h4" className="membership__headline">{texts.members}:</Typography>
                <ul>
                  {document.extra_user_names.map((name, index) => (
                    <li key={index}>{name}</li>
                  ))}
                </ul>
              </>
            )}
            <Typography variant="h4" className="membership__headline">
              {texts.add_member}
            </Typography>
            <CopyToClipboard text={window.location.host + document.invitation_url}>
              <Button variant="contained" onClick={() => setOpenSnackbar(true)}>
                <LinkIcon />
                <Typography padding-right={1}>{texts.copy_invitation_link}</Typography>
              </Button>
            </CopyToClipboard>
            <Typography>{document.invitation_url_valid_until}</Typography>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleClose}
              message={texts.link_copied_to_clipboard}
              action=""
            />
          </Stack>
        </Stack>
        {document.notes && (
          <Box>
            <Typography variant="h4" className="membership__headline">
              Notizen:
            </Typography>
            <p>{document.notes}</p>
          </Box>
        )}
      </Wrapper>

      <Dialog open={openPopup} onClose={() => setOpenPopup(false)} >
        <Stack direction="column" padding={4} gap={2} justifyContent="center" alignItems="center">
          <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
            <h3>{texts.change_my_date}</h3>
            <IconButton onClick={() => setOpenPopup(false)}>
              <Close />
            </IconButton>
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              id="name"
              label={texts.name}
              onChange={(e) => setNewUserData({ ...newUserData, name: e.target.value })}
              type="string"
              value={newUserData.name}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              id="surname"
              label={texts.surname}
              onChange={(e) => setNewUserData({ ...newUserData, surname: e.target.value })}
              type="string"
              value={newUserData.surname}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Stack>
          <TextField
            id="street"
            label={texts.street}
            onChange={(e) => setNewUserData({ ...newUserData, street: e.target.value })}
            type="string"
            value={newUserData.street}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
          <Stack direction="row" gap={2}>
            <TextField
              id="zip"
              label={texts.zip}
              onChange={(e) => setNewUserData({ ...newUserData, zip: e.target.value })}
              type="string"
              value={newUserData.zip}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              id="city"
              label={texts.street}
              onChange={(e) => setNewUserData({ ...newUserData, city: e.target.value })}
              type="string"
              value={newUserData.city}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <Button variant="contained" color="warning" onClick={() => setOpenPopup(false)}>
              {texts.discard}
            </Button>
            <Button variant="contained" color="primary" onClick={() => sendToBackend("change_user_data", newUserData)}>
              {texts.save}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}

export default Membership;