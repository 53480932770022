import { useState } from "react";
import { Stack, Button, Dialog, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Flare, Close } from "@mui/icons-material";
import { PopUpUsers } from "./PopUpUsers";
import { sendToBackend } from "../../helper/websocketHelper";

export const AdminMembershipTabUsers = ({ membershipId, usersOfMembership, onSelect, allUsers }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const addUserToMembership = (userId) => {
    sendToBackend("add_user", { membership_id: membershipId, user_id: userId }, () => {
      // console.log("Added user " + userId + " to membership " + membershipId)
      setDrawerOpen(false)
    });
  }

  const columns = [
    {
      field: "is_main_user",
      key: "mainUser",
      headerName: "Hauptnutzer",
      width: 100,
      renderCell: (props) =>
        props.row.is_main_user ? <Flare color="success" /> : <Close color="error" />
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Telefon", width: 150 },
    { field: "membership_id", headerName: "Mitgliedschaft", width: 150 },
    {
      field: "created_at", headerName: "Angemeldet seit", width: 150, renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }) : ""
    },
    {
      field: "action", headerName: "Aktion", width: 400, renderCell: (params) =>
        <>
          {
            params.row.is_main_user ? <p></p> :
              <Button sx={{ marginRight: 1 }} variant="contained" onClick={() => {
                setMainUser(params.row.id);
              }}>Zu Hauptnutzer machen</Button>
          }
          {/* <Button variant="contained" LinkComponent={Link} target="_blank" to={`/admin_membership_${params.row.id}`}>Öffnen</Button> */}
          <Button variant="contained" sx={{ marginLeft: 1 }} color="error" onClick={() => {
            setSelectedUser(params.row.id);
            setOpenDeleteModal(true);
          }}>entfernen</Button>

        </>

    }
  ];

  const setMainUser = (user_id) => {
    sendToBackend("set_main_user", { membership_id: membershipId, user_id });
  }

  const removeUserFromMembership = () => {
    sendToBackend("remove_user", { membership_id: membershipId, user_id: selectedUser }, () => {
      // console.log("Removed user " + selectedUser + " from membership " + membershipId)
      setOpenDeleteModal(false)
    });
  }

  const close = () => {
    setOpenDeleteModal(false)
  }

  return (
    <>
      <Stack>
        <Button variant="contained" onClick={() => setDrawerOpen(true)} sx={{ margin: "20px auto" }}>Person zur Mitgliedschaft hinzufügen</Button>
        <div style={{ display: "flex", height: "100%", justifyContent: "center" }}>
          <div style={{ flexGrow: 0.8 }}>
            <DataGridPro
              autoHeight
              components={{ Toolbar: GridToolbar }}
              columns={columns}
              rows={usersOfMembership}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              sx={{ height: "500px" }}
            />
          </div>
        </div>
      </Stack>

      <PopUpUsers allUsers={allUsers} onSelect={(userId) => addUserToMembership(userId)} open={drawerOpen} onClose={() => setDrawerOpen(false)} />

      <Dialog onClose={() => setOpenDeleteModal(false)} open={openDeleteModal}>
        <Stack sx={{ margin: "20px" }} justifyContent="center" alignItems="center" gap={2}>
          <Typography >
            Soll die Nutzer_in wirklich aus der Mitgliedschaft entfernt werden?
          </Typography>
          <Button variant="contained" onClick={removeUserFromMembership}>
            Ja, entfernen.
          </Button>
        </Stack>
      </Dialog>

    </>
  )
}
