import { url } from "../../helper/websocketHelper";
import styled from "@emotion/styled";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ImageModal from "./ImageModal";


function ImageSlider({ images }) {
  document.addEventListener("DOMContentLoaded", function () {
    new Splide(".splide").mount();
  });

  return (
  <>
    {images.length > 1 ? (
      <>
        <CustomSplideContainer className="splide">
            {images.map((image, index) => {
              return (
                <SplideSlide
                  options={{
                    rewind: true,
                    gap: "10px",
                  }}
                  className="splide__slide"
                  key={image}
                >
                  <img
                    className="splide__image"
                    src={`${url}${image}`}
                    alt={`image-${index}`}
                  />
                </SplideSlide>
              );
            })}
        </CustomSplideContainer>
      </>
      ) : (
        <Image
          src={`${url}${images[0]}`}
          alt={`image`}
        />
      )}
  </>
  )
}

export default ImageSlider;

const CustomSplideContainer = styled(Splide)(() => ({
  maxHeight: "30vh",

  "& .splide__slide": {
    width: "100%",
  },

  "& .splide__image": {
    aspectRatio: "initial",
    maxHeight: "30vh",
  },
}));

const Image = styled('img')(() => ({
  width: "100%",
  height: "auto"
}))
