import { Container, Typography, Box } from "@mui/material";
import React from "react";

export default function BiddingPage({ data }) {
  const document = data.interaction;
  return (
    <Container>
      <Typography variant="h1">{document.title.value}</Typography>
      <Typography variant="h3">{document.subtitle.value}</Typography>
      <Box
        style={{ marginTop: 30 + "px" }}
        dangerouslySetInnerHTML={{ __html: data.interaction.text.value }}
      ></Box>
    </Container>
  );
}
