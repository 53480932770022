import React from "react";
import { TextField, Typography, Checkbox, Select, MenuItem, Chip, InputAdornment } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Check, Close, } from "@mui/icons-material";
import { FieldObject } from "../types/FieldObject";
import { parseDate } from "../helper/dateHelper"

export const formatCentsToEuro = (cents: number) => {
  return (cents / 100).toFixed(2).replace(".", ",")
}

export const formatEuroToCents = (euro: string | null | undefined) => {
  if (euro == null || euro === undefined) {
    return 0;
  };

  const splitted = euro.split(".");
 // console.log("splitted: " + JSON.stringify(splitted));
  let sum = parseInt(splitted[0]) * 100
  if (splitted.length > 1) {
    sum += parseInt(splitted[1]);
  }
  return sum;
}

export const FieldBuilder = ({ myField }: { myField: FieldObject }, handleChange, userInfos) => {
  switch (myField.fieldType) {
    case "text":
      return (
        <div key={`div-${myField.key}`} style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Typography variant="caption">{myField.name}</Typography>
          <TextField disabled={myField.disabled?.(userInfos)} variant="outlined" key={`textField-${myField.key}`} onChange={(e) => handleChange(myField.key, e.target.value)} value={userInfos[myField.key]} fullWidth />
        </div>
      )
    case "largeText":
      return (
        <div key={`div-${myField.key}`} style={{ marginTop: "10px" }}>
          <Typography variant="caption">{myField.name}</Typography>
          <TextField disabled={myField.disabled?.(userInfos)} variant="outlined" key={myField.key} rows={4} multiline onChange={(e) => handleChange(myField.key, e.target.value)} value={userInfos[myField.key] ?? ""} fullWidth />
        </div>
      )
    case "checkbox":
      return (
        <div key={`div-${myField.key}`} style={{ marginTop: "10px" }}>
          <Typography variant="caption">{myField.name}</Typography>
          <Checkbox disabled={myField.disabled?.(userInfos)} key={myField.key} onChange={(e) => handleChange(myField.key, e.target.value)} checked={userInfos[myField.key] as boolean} />
        </div>
      )
    case "boolean":
      return (
        <div key={`div-${myField.key}`} style={{ marginTop: "10px" }}>
          <Typography variant="caption">{myField.name}</Typography>
          <br />
          {userInfos[myField.key] as boolean ? <Chip color="success" label={(<Typography variant="body1">Aktiv <Check fontSize="small" /></Typography>)} /> : <Chip color="error" label={(<Typography variant="body1">Inaktiv <Close fontSize="small" /></Typography>)} />}
        </div>
      )
    case "select":
      return (
        <div key={`div-${myField.key}`} style={{ marginTop: "10px" }}>
          <Typography variant="caption">{myField.name}</Typography>
          <Select
            disabled={myField.disabled?.(userInfos)}
            value={userInfos[myField.key]}
            // defaultValue={userInfos[myField.key]}
            fullWidth
            variant="outlined"
            onChange={(e) => handleChange(myField.key, e.target.value)}
          >
            {myField.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </div>
      )
    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography variant="caption">{myField.name}</Typography>
          <DatePicker
            disabled={myField.disabled?.(userInfos)}
            renderInput={(props) => <TextField variant="outlined" fullWidth {...props} />}
            // label="DateTimePicker"
            value={parseDate(userInfos[myField.key] as string)}
            inputFormat="DD.MM.YYYY"
            // inputFormat={"E, d. M Y"}
            onChange={(newValue: any) => {
              // console.log("New value", newValue?.format('DD.MM.YYYY'));
              handleChange(myField.key, newValue?.format('YYYY-MM-DD'))
            }}
          />
        </LocalizationProvider>
      )
    case "money":
      return (
        <div key={`div-${myField.key}`} style={{ marginTop: "10px" }}>
          <Typography variant="caption">{myField.name}</Typography>
          <TextField InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }} disabled={myField.disabled?.(userInfos)} variant="outlined" key={myField.key} onChange={(e) => handleChange(myField.key, e.target.value)} value={userInfos[myField.key]} fullWidth />
        </div>
      )
  }
}