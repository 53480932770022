import { arrayMapper, expectNumber, expectString, JsonMapper, JsonMappingError, objectMapper } from "@daniel-faber/json-ts";

export class Verteilpunkt {
    constructor(
        public readonly id: number,
        public readonly code: string,
        // public readonly title: string,
    ) { }

    public static fromJson = objectMapper(accessor => new Verteilpunkt(
        accessor.get("id", expectNumber),
        accessor.get("code", expectString),
        // accessor.get("title", expectString),
    ))

    public static fromJsonArray = arrayMapper(Verteilpunkt.fromJson)
}




export const parseStateObject: JsonMapper<MemberState> = jsonValue => {
    if (typeof jsonValue !== "string" && typeof jsonValue !== "object") {
        throw new JsonMappingError("Expected string or object");
    }
    if (typeof jsonValue === "string") {
        return { None: {} } as MemberState;
    }
    if (typeof jsonValue === "object") {
        return jsonValue as MemberState;
    }
};

export type MemberState = {
    None?: {}
    Waitinglist?: {
        waiting_id: number
    }
    Member?: {
        member_id: number
    },
    MemberWaitinglist?: {
        member_id: number
        waiting_id: number
    },
}

export class MembershipVP {
    constructor(
        public readonly change_at: string,
        public readonly index: number,
        public readonly state: MemberState,
    ) { }

    public static fromJson = objectMapper(accessor => new MembershipVP(
        accessor.get("change_at", expectString),
        accessor.get("index", expectNumber),
        accessor.get("state", parseStateObject),
    ))

    public static fromJsonArray = arrayMapper(MembershipVP.fromJson)
}