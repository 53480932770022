import _ from "lodash";
import { applyPatch } from "fast-json-patch";
import toast from "react-hot-toast";
import { v4 } from "uuid"
import { history } from "../BrowserHistory";

let ws: WebSocket | null = null;
let connected = false;
let edid: string | null = null;
let login_id: string | null = null;
let data: any = null;
let callback: () => void = null;
let search_callback: (result: SearchResult) => void = null;

const location = window.location;
export const ssl = location.protocol === "https:";
let host = location.host;
if (host.indexOf("localhost") !== -1) {
  host = "localhost:1111"
}
export const domain = host;
console.log("HOST:"+host);
export const url = `${location.protocol}//${domain}`;

function uuidv4() {
  return v4();
}

function getLoginId() {
  if (login_id === null) {
    const start = document.cookie.indexOf("login_id=");
    if (start === -1) {
      login_id = uuidv4();
      document.cookie = `login_id=${login_id};SameSite=Strict;path=/`;
    } else {
      login_id = document.cookie.substring(start + 9, start + 9 + 36);
    }
  }
}
function getEdid() {
  if (edid === null) {
    edid = uuidv4();
  }
}

export function connectToWebsocket(setData: (value: unknown) => void) {
  if (ws !== null) return;

  getLoginId();
  getEdid();

  function getLang() {
    if (navigator.languages !== undefined) return navigator.languages[0];
    return navigator.language;
  }

  const message = {
    name: "register",
    payload: {
      edid,
      locale: getLang(),
    },
  };
  // Create WebSocket connection.
  const url = `ws${ssl ? "s" : ""}://${domain}/ws`;
  ws = new WebSocket(url);

  // Connection opened
  ws.addEventListener("open", function (event) {
    ws.send(JSON.stringify(message));
  });

  // Listen for messages
  ws.addEventListener("message", function (event) {
    // console.log('Message from server ', event.data);
    const payload = JSON.parse(event.data);
    if (payload.name === "connected") {
      connected = true;
      sendHelo();
    }
    if (payload.name === "screen") {
      handleScreen(payload.payload, setData);
    } else if (payload.name === "end" && callback !== null) {
      callback();
      callback = null;
    } else if (payload.name === "message") {
      if (
        payload.payload.type === "success" ||
        payload.payload.type === "info"
      ) {
        toast.success(payload.payload.msg);
      } else {
        toast.error(payload.payload.msg);
      }
    } else if (payload.name === "load_file") {
      (document.getElementById("dld") as any).src = payload.payload.path;
    } else if (payload.name === "change_path") {
      // console.log("change path to", payload.payload.path);
      history.replace(payload.payload.path);
    } else if (payload.name === "searchResult") {
      if (search_callback != null) {
        search_callback(payload.payload);
        search_callback = null;
      }
    }
  });

  ws.addEventListener("close", function (event) {
    connected = false;
    ws = null;
    setTimeout(() => {
      connectToWebsocket(setData);
    }, 100);
  });

  ws.addEventListener("error", function (event) {
    connected = false;
    ws = null;
    setTimeout(() => {
      connectToWebsocket(setData);
    }, 100);
  });
}

export type Query = {
  term: string | undefined,
  page: Number | undefined,
  document_team: string | undefined,
  document_topic: string | undefined,
  event_category: string | undefined,
};

export type SearchResult = {
  pages: Number,
  results: any[],
};
export async function search(query: Query): Promise<SearchResult> {
  let payload = query as any;
  payload.edid = edid;
  payload.login_id = login_id;

  ws.send(JSON.stringify({
    name: "search",
    payload,
  }));

  const promise = new Promise<SearchResult>((res, rej) => {
    search_callback = res;
  });

  return promise;
}

export function sendHelo(path?: string) {
  if (connected === false) {
    return;
  }
  const pathname = path || window.location.pathname;
  ws.send(
    JSON.stringify({ name: "helo", payload: { edid, login_id, pathname } })
  );
}

export function sendToBackend(interaction: unknown, p?: any, cb?: () => void) {
  if (cb !== undefined) {
    if (callback === null) {
      callback = cb;
    } else {
      return cb();
    }
  }

  let payload: Partial<{
    interaction: any;
    edid: string | null;
  }> = {};
  if (p) {
    payload = p;
  }
  payload.interaction = interaction;
  payload.edid = edid;
  // console.log("payload", payload)
  const action = { name: "action/genericAction", payload: payload };
  ws.send(JSON.stringify(action));
}

function handleScreen(newScreen: any, setData: (value: unknown) => void) {
  if (newScreen.update === "diff") {
    applyScreenDiff(newScreen, setData);
  } else if (newScreen.update === "patch") {
    applyScreenPatch(newScreen, setData);
  } else {
    data = newScreen;
    setData(newScreen);
  }
}

function applyScreenDiff(newScreen: any, setData: (value: unknown) => void) {
  const applyDiff = (obj: any, diff: any) => {
    if (!_.isPlainObject(obj) || !_.isPlainObject(diff)) {
      return;
    }
    Object.keys(diff).forEach((key) => {
      const val = diff[key];
      if (_.isPlainObject(val)) {
        if (!_.isPlainObject(obj[key])) {
          obj[key] = {};
        }
        applyDiff(obj[key], val);
      } else {
        obj[key] = val;
      }
    });
  };

  let d = _.cloneDeep(data);
  applyDiff(d, newScreen);
  data = d;
  setData(d);
}

function applyScreenPatch(newScreen: any, setData: (value: unknown) => void) {
  let d = _.cloneDeep(data);
  applyPatch(d, newScreen.patch ?? []);
  data = d;
  setData(d);
}
