import React from 'react'
import { Box, TextField, Typography, Container, Button } from "@mui/material";
import { sendToBackend } from '../helper/websocketHelper';
import styled from '@emotion/styled';

export const RegisterUser = ({ data }) => {
  const texts = data.interaction.texts.value;
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const formdata = new FormData(event.currentTarget);

    sendToBackend("form", {
      user: formdata.get('username'),
      email: formdata.get('email'),
      name: formdata.get("name"),
      phone: formdata.get('phone')
    })
  };

  return (
    <Container>

      <Typography variant='h2'>
        {texts.title}
      </Typography>

      <StyledBox component="form" onSubmit={handleSubmit}>

        <TextField
          margin="normal"
          required
          name="username"
          label={texts.username}
          type="text"
        />

        <TextField
          margin="normal"
          name="name"
          label={texts.name}
          type="text"
        />

        <TextField
          margin="normal"
          required
          name="email"
          label={texts.email}
          type="email"
        />

        <TextField
          margin="normal"
          name="phone"
          label={texts.phone}
          type="tel"
        />

        <Typography sx={{ marginTop: 3, width: "100%" }}>
          {texts.description}
        </Typography>

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 4 }}
        >
          {texts.submit}
        </Button>

      </StyledBox>
    </Container>
  )
}

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "4%",
  marginTop: 1,

  "& div": {
    minWidth: "48%"
  }
}));