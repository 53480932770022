import React from "react";
import { Box, Button, CircularProgress, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DataGridPro, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid-pro";
import { UploadFile } from "@mui/icons-material";
import { PopUpMemberships } from "./AdminMembership/PopUpMemberships.tsx";
import { sendToBackend } from "../helper/websocketHelper";
import moneyFormater from "../helper/moneyFormater.tsx";


export default function AdminTransactions({ data }) {

    const [loading, setLoading] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [csvLoading, setCsvLoading] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [openPopup, setOpenPopup] = React.useState(false)
    const [selectedYear, setSelectedYear] = React.useState<string | undefined>(data.interaction.select_year.value)

    const members = data.interaction.memberships.value;
    const SelectEditInputCell = (props: GridRenderCellParams) => {
        const { id, value } = props;
        const handleChange = async (event: any) => {
            setLoading(true);
            sendToBackend("set_year", { year: parseInt(`${event.target.value}`), transaction_id: id }, () => {
                setLoading(false);
            })
        };
        return (
            <Select
                variant="standard"
                value={value}
                onChange={handleChange}
                size="small"
                sx={{ height: 1 }}
                native
                autoFocus
            >
                {data.interaction.select_year.options.map((year) => {
                    return <option key={year.value} value={year.value}>{year.text}</option>
                })}
            </Select>
        );
    }

    const handleSelectionChange = (newSelection) => {
        setSelectedRows(newSelection);
    }

    const columns = [
        {
            field: "date", headerName: "Datum", width: 100, renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }) : ""
        },
        { field: "year", headerName: "M.-Jahr", width: 100, renderCell: SelectEditInputCell },
        {
            field: "cents", headerName: "Betrag", width: 120, renderCell: (params) => {
                return moneyFormater(params.value)
            }
        },

        { field: "description", headerName: "Beschreibung", width: 400 },
        {
            field: "membership", headerName: "Mitgliedschaft", width: 200, renderCell: (params) => {
                const member = members.find((member) => member.id === params.value);
                return (<Typography>
                    {member?.name} {member?.surname}
                </Typography>)
            }
        },
        {
            field: "id", headerName: " ", width: 200, renderCell: (params) => {
                const isSelected = selectedRows.includes(params.row.id);
                if (isSelected) {
                    return <Button variant="contained" color={params.row.membership ? "secondary" : "primary"} onClick={(e) => {
                        e.stopPropagation();
                        setOpenPopup(true);
                    }}>{params.row.membership ? "Zuweisung ändern" : "Zuweisen"}</Button>
                } else {
                    return "";
                }
            },
        }
    ];

    const handleUploadClick = async () => {
        // open a text file and read the content without showOpenFilePicker
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = ".csv";

        const text = await new Promise<string>((resolve) => {
            input.onchange = async () => {
                const files = input.files;
                if (files && files.length > 0) {
                    const file = files[0];
                    const reader = new FileReader();
                    reader.readAsText(file, "UTF-8");
                    reader.onload = (evt) => {
                        const result = evt.target?.result;
                        if (typeof result === "string") {
                            resolve(result);
                        }
                    };
                    reader.onerror = (evt) => {
                        alert(`error reading file ${evt}`);
                    };
                }
            };
            input.click();
        });
        setCsvLoading(true);
        sendToBackend("upload_csv", { value: text }, () => {
            // console.log("CSV Uploaded")
            setCsvLoading(false);
        });
    }
    return (
        <>
            <Typography variant="h2">Übersicht Beiträge</Typography>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ marginBottom: 2 }}>
                <Select sx={{ minWidth: "10vw" }} variant="standard" label="Geschäftsjahr" value={selectedYear} onChange={(e) => {
                    setSelectedYear(e.target.value as string);
                    setLoading(true)
                    sendToBackend("select_year", { year: e.target.value }, () => {
                        setLoading(false);
                    })
                }}>
                    {
                        data.interaction.select_year.options.map((year) => {
                            return <MenuItem key={year.value} value={year.value}>{year.text}</MenuItem>
                        })
                    }
                </Select>
                <Typography>Gesamtsumme: {moneyFormater(data.interaction.transactions_sum?.value)}</Typography>
                {csvLoading ? <CircularProgress /> : <Button variant="contained" onClick={() => handleUploadClick()}><UploadFile /> Upload CSV</Button>}
            </Stack>
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGridPro
                        autoHeight
                        getRowId={(row) => row.id}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 200 },
                            }
                        }}
                        columns={columns}
                        rows={data.interaction.transactions.value}
                        pagination
                        checkboxSelection
                        loading={loading}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        selectionModel={selectedRows}
                        onSelectionModelChange={handleSelectionChange}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        sx={{ height: "500px" }}
                    />
                </div>
            </div>
            {openPopup && <Box>
                <PopUpMemberships
                    members={members}
                    onSelect={(member) => {
                        sendToBackend("transaction_set_membership", {
                            transaction_ids: selectedRows,
                            membership_id: member.id,
                        });
                        setSelectedRows([]);
                        setOpenPopup(false);
                    }}
                    open={openPopup !== undefined}
                    onClose={() => setOpenPopup(false)}
                />
            </Box>}
        </>
    );
}