import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import moneyFormater from "../../helper/moneyFormater";
import { PopUpMemberships } from "./PopUpMemberships";
import { sendToBackend } from "../../helper/websocketHelper";

export default function AdminMemberShipTabTransactions({ data }) {
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [openPopup, setOpenPopup] = React.useState(false)

    const transactions = data.interaction.transactions.value;
    const members = data.interaction.memberships.value;

    const handleSelectionChange = (newSelection) => {
        setSelectedRows(newSelection);
    }

    const columns = [
        {
            field: "date", headerName: "Datum", width: 120, renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }) : ""
        },
        { field: "year", headerName: "Mitgliedschaftsjahr", width: 150 },
        {
            field: "cents", headerName: "Betrag", width: 120, renderCell: (params) => {
                return moneyFormater(params.value);
            }
        },
        { field: "description", headerName: "Beschreibung", width: "800" },
        {
            field: "id", headerName: " ", width: 200, renderCell: (params) => {
                const isSelected = selectedRows.includes(params.row.id);
                if (isSelected) {
                    return <Button variant="contained" color={params.row.membership ? "secondary" : "primary"} onClick={(e) => {
                        e.stopPropagation();
                        setOpenPopup(true);
                    }}>{params.row.membership ? "Zuweisung ändern" : "Zuweisen"}</Button>
                } else {
                    return "";
                }
            },
        }
    ];

    return (
        <Box style={{ display: "flex", height: "100%" }}>
            <Box style={{ flexGrow: 1 }}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    components={{ Toolbar: GridToolbar }}
                    columns={columns}
                    pagination
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rows={transactions}
                    sx={{ height: "500px" }}
                    loading={loading}
                    selectionModel={selectedRows}
                    onSelectionModelChange={handleSelectionChange}
                    checkboxSelection
                />
            </Box>
            {openPopup && <Box>
                <PopUpMemberships
                    members={members}
                    onSelect={(member) => {
                        sendToBackend("transaction_set_membership", {
                            transaction_ids: selectedRows,
                            membership_id: member.id,
                        });
                        setSelectedRows([]);
                        setOpenPopup(false);
                    }}
                    open={openPopup !== undefined}
                    onClose={() => setOpenPopup(false)}
                />
            </Box>}
        </Box>
    )
}
