import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useCallback } from "react";
import { sendToBackend } from "../helper/websocketHelper";
import "moment/locale/fr";
import "moment/locale/de";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

function Cal({ data }) {
  const lang = {
    en: null,
    fr: {
      week: "La semaine",
      work_week: "Semaine de travail",
      day: "Jour",
      month: "Mois",
      previous: "Antérieur",
      next: "Prochain",
      today: `Aujourd'hui`,
      agenda: "Ordre du jour",

      showMore: (total) => `+${total} plus`,
    },
    de: {
      week: "Woche",
      work_week: "Arbeitswoche",
      day: "Tag",
      month: "Monat",
      previous: "Davor",
      next: "Danach",
      today: "Heute",
      agenda: "Agenda",

      showMore: (total) => `+${total} plus`,
    },
  };

  let history = useNavigate();
  const localizer = momentLocalizer(moment);
  const calendarValue = data.interaction.calendar.value;
  const culture = data.interaction.static_texts.value.culture;
  const messages = lang[culture];
  const events = calendarValue.events.map((event) => {
    return {
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      created_by: event.created_by,
      path: event.path,
      category: event.category,
    };
  });

  const calendarCallback = useCallback((payload) => {
    sendToBackend("calendar", { payload });
  }, []);

  function eventStyleGetter(event, start, end, isSelected) {
    let backgroundColor = "black";
    let color = "white";
    switch (event.category) {
      case "Fahrradverteilung":
        backgroundColor = "#d5cabf";
        break;
      case "Sprinter":
        backgroundColor = "#ccddca";
        color = "black";
        break;
      case "Koko":
        backgroundColor = "#729a87";
        break;
      case "Tunsel":
        backgroundColor = "#81371c";
        break;
      case "Sonstige":
        backgroundColor = "#e9b528";
        color = "black";
        break;
    }
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color,
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  }

  const handleClick = (e) => {
    // console.log(e);
    history(e.path);
  };

  return (
    <Box>
      <Typography variant="h1">Kalender</Typography>
      <Calendar
        min={new Date(2010, 10, 0, 8, 0, 0)}
        max={new Date(2010, 10, 0, 22, 0, 0)}
        culture={culture}
        messages={messages}
        localizer={localizer}
        events={events}
        view={calendarValue.view}
        style={{ height: 500 }}
        onRangeChange={calendarCallback}
        onView={calendarCallback}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={handleClick}
      />
    </Box>
  );
}

export default Cal;
