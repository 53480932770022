import React, { useState, useEffect, useLayoutEffect } from "react";
import { connectToWebsocket, sendHelo } from "./helper/websocketHelper";
import Header from "./components/Header";
import Footer from "./components/Footer";
import News from "./components/News";
import Event from "./components/Event";
import BiddingPage from "./components/BiddingPage";
import Membership from "./components/Membership";
import User from "./components/User.js";
import VP from "./components/VP";
import Document from "./components/Doc";
import Cal from "./components/Calendar";
import { Box, Container } from "@mui/material";
import ScreenNotFound from "./components/ScreenNotFound";
import HtmlText from "./components/HtmlText";
import { Helmet } from "react-helmet";
import _ from "lodash";
import AccessDenied from "./components/AccessDenied";
import ScrollToTop from "./components/ScrollToTop";
import styled from "@emotion/styled";
import { Toaster } from "react-hot-toast";
import BiddingAdmin from "./components/BiddingAdmin";
import AdminMemberships from "./components/AdminMemberships";
import VPs from "./components/VPs";
import { AdminMembership } from "./components/AdminMembership/AdminMembership.tsx";
import AdminTransactions from "./components/AdminTransactions.tsx";
import AdminDeposits from "./components/AdminDeposits";
import { Start } from "./components/Start.tsx";
import { useLocation } from "react-router-dom";
import AdminIncomes from "./components/AdminIncomes/AdminIncomes";
import { Income } from "./components/AdminIncomes/Income";
import { Invitation } from "./components/Invitation.js";
import { ForgotPassword } from "./components/ForgotPassword.js";
import { ActivateUser } from "./components/ActivateUser.js";
import { RegisterUser } from "./components/RegisterUser.js";

const Screens = {
  AccessDenied: AccessDenied,
  ActivateUser: ActivateUser,
  AdminDeposits: AdminDeposits,
  AdminIncomes: AdminIncomes,
  AdminMembership: AdminMembership,
  AdminMemberships: AdminMemberships,
  AdminTransactions: AdminTransactions,
  BiddingAdmin: BiddingAdmin,
  BiddingPage: BiddingPage,
  Calendar: Cal,
  Document: Document,
  Event: Event,
  ForgotPassword: ForgotPassword,
  Income: Income,
  Invitation: Invitation,
  HtmlText: HtmlText,
  Membership: Membership,
  News: News,
  User: User,
  RegisterUser: RegisterUser,
  Start: Start,
  VP: VP,
  VPs: VPs,
};

function getComponent(data, loggedIn) {
  const name = data.ui.name;
  // console.log("template:", name);
  const component = Screens[name];
  if (!component) {
    // console.log("not found");
    return (
      <StyledContainer>
        <ScreenNotFound name={name} />
      </StyledContainer>
    )
  } else {
    if (name.startsWith("Admin")) {
      return (
        <Box sx={{
          position: "relative",
          maxWidth: "95vw",
          minWidth: "80vw",
          overflow: "hidden",
          margin: "0 auto",
        }}>
          <ScrollToTop />
          {React.createElement(component, { data, loggedIn })}
        </Box>
      )
      // return React.createElement(component, { data, loggedIn });
    } else {
      return (
        <StyledContainer>
          <ScrollToTop />
          {React.createElement(component, { data, loggedIn })}
        </StyledContainer>
      )
    }
  }
}

function App() {
  const [data, setData] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [menu, setMenu] = useState([]);
  const [userMenu, setUserMenu] = useState([]);
  const [staticTexts, setStaticTexts] = useState([]);
  const [modifyUsers, setModifyUsers] = useState(false);

  let location = useLocation();
  useLayoutEffect(() => {
    // console.log("location changed!", location);
    sendHelo();
  }, [location]);


  useEffect(() => {
    connectToWebsocket(setData);
  }, []);

  useEffect(() => {
    if (data.ui) {
      setMenu(data.interaction.menu.value);
      setUserMenu(data.interaction.menu.user);
      setLoggedIn(data.interaction.logged_in.value);
      setStaticTexts(data.interaction.static_texts.value);
      setModifyUsers(data.interaction.modify_users.value);
    }
  }, [data]);
  const rss_link = _.get(data, "interaction.rss_link.value");

  console.log("data ", data.interaction);
  console.log("UI PAGE: ", data?.ui?.name)
  if (data?.ui?.name === "Start") {
    return <Start staticTexts={staticTexts} data={data} loggedIn={loggedIn} modifyUsers={modifyUsers} />
  }
  return (
    <>
      <Helmet>
        {rss_link ? (
          <link
            type="application/rss+xml"
            rel="alternate"
            title="Gartencoop Dokumente"
            href={rss_link}
          />
        ) : (
          ""
        )}
      </Helmet>
      <Toaster
        toastOptions={{
          duration: 10000,
        }}
      />
      {data.ui ? (
        <Layout>
          <Header
            modifyUsers={modifyUsers}
            menu={menu}
            userMenu={userMenu}
            loggedIn={loggedIn}
            NoMembership={data.interaction.no_membership}
            bidding={data.interaction.bidding}
            staticTexts={staticTexts}
            searchAvailable={data.interaction.search_available?.value}
            searchFilters={{
              document_teams: data.interaction.document_teams.value,
              document_topics: data.interaction.document_topics.value,
              event_categories: data.interaction.event_categories.value
            }}
          />
          {/* Alex Style changes, die nicht so ganz klaar sind */}

          {getComponent(data, loggedIn)}

          <Footer staticTexts={staticTexts} />
        </Layout>
      ) : (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
    </>
  );
}

export default App;

const Layout = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "100vw",
  padding: 0,
  margin: 0,
  minHeight: "100vh"
}));

const StyledContainer = styled(Container)(() => ({
  position: "relative",
  maxWidth: "100vw",
  overflow: "hidden",
  padding: 0,
  flex: "1 1 100%"
}));

const LoaderContainer = styled(Box)(() => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  background: "white",
  zIndex: 1,
}));

const Spinner = styled(Box)(() => ({
  width: "64px",
  height: "64px",
  border: "8px solid",
  borderColor: "black transparent black transparent",
  borderRadius: "50%",
  animation: "spin-anim 1.2s linear infinite",
}));
