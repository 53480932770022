import React, { useState, useEffect } from "react";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import styled from '@emotion/styled';
import { IconButton } from "@mui/material";
import theme from "../theme";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showTopBtn && (
        <GoToTop onClick={goToTop}>
          <ArrowCircleUpIcon sx={{ fontSize: 40 }} />
        </GoToTop>
      )}
    </>
  );
};
export default ScrollToTop;

const GoToTop = styled(IconButton)(() => ({
  position: "fixed",
  bottom: 5,
  right: 5,
  zIndex: 1,
  cursor: "pointer",
  background: "#FFFFFFAA",
  [theme.breakpoints.up('md')]: {
    bottom: 10,
    right: 100,
  },
}));