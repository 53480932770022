import React from "react";
import { Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../../theme";

export default function NoMembershipBanner({ text }) {
  return (
    <Wrapper>
      <Typography>{text}</Typography>
    </Wrapper>
  );
}

// Styling
const Wrapper = styled(Stack)(({}) => ({
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  zIndex: 6,
  width: "100%",

  padding: "10px 20px",

  background: theme.palette.red,

  "& p": {
    color: theme.palette.white,
    margin: "0 auto",
  },
}));
