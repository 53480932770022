import { TextField, Box, Dialog, IconButton } from '@mui/material'
import { useState } from 'react'
import styled from '@emotion/styled';
import theme from '../../theme';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Editor from './Editor';
import { sendToBackend } from '../../helper/websocketHelper';

function EditDocument({ body, id }) {
  const [editOpen, setEditOpen] = useState(false);
  const [markdown, setMarkdown] = useState(body);
  return (
    <Box>
      <EditIcon onClick={() => setEditOpen(true)} />
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        fullScreen={true}
        fullWidth
        maxWidth="xl"
      >
        <ButtonBox>
          <StyledButton onClick={() => {
            sendToBackend("save", {
              markdown,
            });
            setEditOpen(false);
          }}>
            <SaveIcon />
          </StyledButton>
          <StyledButton onClick={() => setEditOpen(false)}>
            <CloseIcon />
          </StyledButton>
        </ButtonBox>
        <Box>
          <Editor body={markdown} id={id} onChange={(dt) => setMarkdown(dt)} />
        </Box>
      </Dialog>
    </Box>
  )
}

export default EditDocument

// Styling
const StyledButton = styled(IconButton)(() => ({
  color: theme.palette.black,
  background: `${theme.palette.stone}44`,
  transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
    background: `${theme.palette.stone}ff`
  }
}));

const StyledTextField = styled(TextField)(() => ({
  margin: "0 20px",
  minWidth: "80%",
  "& .MuiPaper-root": {
    width: "100%",
  }
}));

const ButtonBox = styled(Box)(() => ({
  padding: "30px 100px 30px 30px",
  display: "flex",
  alignItems: "center",
  gap: 30,
  justifyContent: "right"
}));

