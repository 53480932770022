import { Pie } from '@ant-design/plots';

type PieChartProps = {
    data: { [key: string]: number };
    title: string;
}

export const PieChart = ({ data, title }: PieChartProps) => {
    const parseData: { type: string, value: number }[] = [];

    for (const [key, value] of Object.entries(data)) {
        parseData.push({ type: key, value: value })
    }

    const config = {
        appendPadding: 10,
        data: parseData,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        theme: {
            colors10: [
                "#771155", "#AA4488", "#CC99BB", "#114477", "#4477AA", "#77AADD", "#117777", "#44AAAA", "#77CCCC", "#117744", "#44AA77", "#88CCAA", "#777711", "#AAAA44", "#DDDD77", "#774411", "#AA7744", "#DDAA77", "#771122", "#AA4455", "#DD7788"
            ]
        },
        label: {
            type: 'outer',
            content: '{name} {value}',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };
    return <Pie {...config} />;
};
