import { Button, Chip, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react";
import { UserInfos } from "../../types/UserInfos";
import theme from "../../theme";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Membership } from "../../types/Membership";

export type AdminMembershipHeaderProps = {
  userInfos: UserInfos;
}

export const AdminMembershipHeader = ({ userInfos }: AdminMembershipHeaderProps) => {
  let member = Membership.fromJson(userInfos);
  let { color, label, whiteText, opacity } = member.getLabelAndColor(dayjs());

  return (
    <>
      {/* <Typography variant="h5" sx={{ marginTop: 2, textAlign: "center", width: "100%" }}>
        Mitgliedschaft von 
      </Typography> */}

      {/* info only */}
      <Stack flexWrap="wrap" direction="row" spacing={2} alignItems="center" sx={{ margin: 2, padding: 2, background: theme.palette.lightgreen, borderRadius: 5 }} justifyContent="space-evenly">
        <Button LinkComponent={Link} to="/admin_memberships" variant="outlined" sx={{ color: "#333"}} >
          zurück zur Tabelle
        </Button>
        <div >
          {<Chip size="small" label={label} sx={{
            bgcolor: color,
            color: whiteText ? "white" : "black",
            opacity: opacity ? 0.5 : 1
          }} />}
        </div>
        <Typography variant="body1">
          <strong>ID: </strong>{userInfos.id}
        </Typography>
        <Typography variant="body1" >
          <strong>Mitgliedschaft: </strong>{userInfos.name} {userInfos.surname}
        </Typography>
        <Typography variant="body1">
          <strong>E-Mail: </strong>{userInfos.main_user_email},
        </Typography>
        <Typography variant="body1">
          <strong>Telefon: </strong>{userInfos.main_user_phone}
        </Typography>
        {/*         <Button onClick={() => setBidDrawerState(true)} sx={{ textDecoration: "underline", textTransform: "none" }}>
          <Typography variant="body1">
            <strong>Gebot: </strong> {userInfos.bid}€
          </Typography>
        </Button> */}
      </Stack>
    </>
  )
}