import React, { useEffect } from "react";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { UploadFile } from "@mui/icons-material";
import { PopUpMemberships } from "./AdminMembership/PopUpMemberships.tsx";
import { sendToBackend } from "../helper/websocketHelper";
import moneyFormater from "../helper/moneyFormater.tsx";


export default function AdminDeposits({ data }) {

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [csvLoading, setCsvLoading] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [openPopup, setOpenPopup] = React.useState(false)

    const members = data.interaction.memberships.value;
    const handleSelectionChange = (newSelection) => {
        setSelectedRows(newSelection);
    }

    useEffect(() => {
        // console.log(selectedRows);
    }, [selectedRows])

    const columns = [
        {
            field: "date", headerName: "Datum", width: 100, renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }) : ""
        },
        {
            field: "cents", headerName: "Betrag", width: 120, renderCell: (params) => {
                return moneyFormater(params.value)
            }
        },

        { field: "description", headerName: "Beschreibung", width: 400 },
        {
            field: "membership", headerName: "Mitgliedschaft", width: 200, renderCell: (params) => {
                const member = members.find((member) => member.id === params.value);
                return (<Typography>
                    {member?.name} {member?.surname}
                </Typography>)
            }
        },
        {
            field: "id", headerName: " ", width: 200, renderCell: (params) => {
                const isSelected = selectedRows.includes(params.row.id);
                if (isSelected) {
                    return <Button variant="contained" color={params.row.membership ? "secondary" : "primary"} onClick={(e) => {
                        e.stopPropagation();
                        setOpenPopup(true);
                    }}>{params.row.membership ? "Zuweisung ändern" : "Zuweisen"}</Button>
                } else {
                    return "";
                }
            },
        }
    ];

    const handleUploadClick = async () => {
        // open a text file and read the content without showOpenFilePicker
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = ".csv";

        const text = await new Promise<string>((resolve) => {
            input.onchange = async () => {
                const files = input.files;
                if (files && files.length > 0) {
                    const file = files[0];
                    const reader = new FileReader();
                    reader.readAsText(file, "UTF-8");
                    reader.onload = (evt) => {
                        const result = evt.target?.result;
                        if (typeof result === "string") {
                            resolve(result);
                        }
                    };
                    reader.onerror = (evt) => {
                        alert(`error reading file ${evt}`);
                    };
                }
            };
            input.click();
        });
        setCsvLoading(true);
        sendToBackend("upload_csv", { value: text }, () => {
            // console.log("CSV Uploaded")
            setCsvLoading(false);
        });
    }
    return (
        <>
            <Typography variant="h2">Übersicht Einlagen</Typography>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ marginBottom: 2 }}>
                <Typography>Gesamtsumme: {moneyFormater(data.interaction.deposits_sum?.value)}</Typography>
                {csvLoading ? <CircularProgress /> : <Button variant="contained" onClick={() => handleUploadClick()}><UploadFile /> Upload CSV</Button>}
            </Stack>
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGridPro
                        autoHeight
                        getRowId={(row) => row.id}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 200 },
                            }
                        }}
                        columns={columns}
                        rows={data.interaction.deposits.value}
                        pagination
                        checkboxSelection
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onSelectionModelChange={handleSelectionChange}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        selectionModel={selectedRows}
                        sx={{ height: "500px" }}
                    />
                </div>
            </div>
            {openPopup && <Box>
                <PopUpMemberships
                    members={members}
                    onSelect={(member) => {
                        sendToBackend("set_membership", {
                            deposit_ids: selectedRows,
                            membership_id: member.id,
                        });
                        setSelectedRows([]);
                        setOpenPopup(false);
                    }}
                    open={openPopup !== undefined}
                    onClose={() => setOpenPopup(false)}
                />
            </Box>}
        </>
    );
}