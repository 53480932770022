import * as React from 'react';
import {Button, CssBaseline, TextField, Link, Stack, Box, Typography, Container, IconButton} from '@mui/material';
import { sendToBackend } from '../helper/websocketHelper';
import Close from '@mui/icons-material/Close';

export default function Login({ staticTexts, closeDrawer, modifyUsers }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    /* console.log({
      username: data.get('username'),
      password: data.get('password'),
    }); */
    sendToBackend("login", {
      username: data.get('username'),
      password: data.get('password'),
    })
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ position: "relative"}}>
      <CssBaseline />
      <IconButton onClick={closeDrawer} sx={{ position: 'absolute', top: 8, left: 8 }}>
        <Close />
      </IconButton>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {staticTexts.login}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label={staticTexts.login_username}
            name="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={staticTexts.login_password}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 4 }}
          >
            {staticTexts.login}
          </Button>

          {modifyUsers &&
            <Stack direction="row" justifyContent="space-between">
              <Link href="https://www.gartencoop.org/tunsel/user/password" variant="body2">
                {staticTexts.login_forgot_password}  
              </Link>
              <Link href="https://www.gartencoop.org/tunsel/user/register" variant="body2">
                {staticTexts.login_register}
              </Link>
            </Stack>
          }
        </Box>
      </Box>
    </Container>
  );
}