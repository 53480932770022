import { Typography, Stack } from "@mui/material"
import React from "react"

export const AdminMembershipOverview = ({ financePrediction }) => {

    const fields = [
        {
            name: "Anzahl Transaktionen",
            source: financePrediction.count
        },
        {
            name: "Geboten",
            source: financePrediction.expected_sum,
            type: "money"
        },
        {
            name: "Bezahlte Summe",
            source: financePrediction.current_sum,
            type: "money"
        },
        {
            name: "Differenz",
            source: financePrediction.diff,
            type: "money"
        },
        {
            name: "Monate aktiver Mitgliedschaft",
            source: financePrediction.months
        },
    ]

    return (
        <Stack alignItems="center">
            <Typography variant="h2">Fiskalvorhersage</Typography>
            {fields.map(field => field.type === "money" ? (
                <Stack direction="row" alignItems={"flex-start"} gap={1}>
                    <Typography>{field.name}:</Typography>
                    <Typography color={field.source < 0 ? "red" : "black"}>{(field.source / 100).toFixed(2)} €</Typography>
                </Stack>
            ) : (
                <Stack direction="row" alignItems={"flex-start"} gap={1}>
                    <Typography>{field.name}:</Typography>
                    <Typography>{field.source}</Typography>
                </Stack>
            ))}
        </Stack>
    )
}