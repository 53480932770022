import { arrayMapper, enumMapperByValue, expectBoolean, expectNumber, expectString, JsonMapper, JsonMappingError, objectMapper, } from "@daniel-faber/json-ts"
import dayjs, { Dayjs } from "dayjs"
export enum SubscriptionType {
    WaitingList = "WaitingList",
    Team = "Team",
    FreeVegetables = "FreeVegetables",
    Active = "Active",
    TestMembership = "TestMembership",
    NoVegetables = "NoVegetables"
}

export const parseSimpleDate: JsonMapper<Dayjs | null> = jsonValue => {
    // console.log("typeof jsonValue: " + typeof jsonValue);
    if (typeof jsonValue !== "string") {
        return null;
    }
    if (jsonValue === "" || jsonValue.length === 0) return null;
    const parsedDate = dayjs(jsonValue, "YYYY-MM-DD");
    if (!parsedDate.isValid()) {
        throw new JsonMappingError("Expected string to parse date");
    }
    return parsedDate;
};

export const parseFinance: JsonMapper<Object | null> = jsonValue => {
    if (jsonValue === null || typeof jsonValue !== "object") {
        return null;
    }
    return jsonValue;
}

export const expectStringOrNull: JsonMapper<string | null> = jsonValue => {
    if (jsonValue === null) return null;
    if (typeof jsonValue !== "string") {
        throw new JsonMappingError("Expected string");
    }
    return jsonValue;
};

export class Membership {

    constructor(
        public readonly has_current_bid: boolean,
        public readonly city: string,
        public readonly created_at: Dayjs | null,
        public readonly deposit: number,
        public readonly end_at: Dayjs | null,
        public readonly extra_user_names: Array<string>,
        public readonly finance: Object | null,
        public readonly id: number,
        public readonly active_months: number,
        public readonly bid_cents_for_current_year: number,
        public readonly main_user_name: string | null,
        public readonly name: string,
        public readonly notes: string | null,
        public readonly shares: string,
        public readonly start_at: Dayjs | null, //"2011-01-01",
        public readonly street: string,
        public readonly subscription: SubscriptionType,
        public readonly surname: string,
        public readonly user_names: Array<string>,
        public readonly vp_code: string | null,
        public readonly vp_path: string | null,
        public readonly vp_title: string | null,
        public readonly zip: string
    ) { }

    public static readonly fromJson = objectMapper(accessor => new Membership(
        accessor.get("has_current_bid", expectBoolean),
        accessor.get("city", expectString),
        accessor.getOptional("created_at", parseSimpleDate),
        accessor.get("deposit", expectNumber),
        accessor.getOptional("end_at", parseSimpleDate),
        accessor.get("extra_user_names", arrayMapper(expectString)),
        accessor.get("finance", parseFinance),
        accessor.get("id", expectNumber),
        accessor.get("active_months", expectNumber),
        accessor.get("bid_cents_for_current_year", expectNumber),
        accessor.get("main_user_name", expectStringOrNull),
        accessor.get("name", expectString),
        accessor.get("notes", expectStringOrNull),
        accessor.get("shares", expectString),
        accessor.getOptional("start_at", parseSimpleDate),
        accessor.get("street", expectString),
        accessor.get("subscription", enumMapperByValue(SubscriptionType)),
        accessor.get("surname", expectString),
        accessor.get("user_names", arrayMapper(expectString)),
        accessor.get("vp_code", expectStringOrNull),
        accessor.get("vp_path", expectStringOrNull),
        accessor.get("vp_title", expectStringOrNull),
        accessor.get("zip", expectString)
    ));

    public static readonly fromJsonArray = arrayMapper(Membership.fromJson);

    public getLabelAndColor = (date: Dayjs) => {
        let color = ""
        let coreLabel = ""
        let whiteText = false
        let opacity = false
        switch (this.subscription) {
            case SubscriptionType.WaitingList:
                color = "brown"
                coreLabel = "Warteliste"
                whiteText = true
                break;
            case SubscriptionType.NoVegetables:
                color = "gold"
                coreLabel = "Passiv"
                break;
            case SubscriptionType.Active:
                color = "green"
                coreLabel = "Aktiv"
                whiteText = true
                break;
            case SubscriptionType.TestMembership:
                color = "orange"
                coreLabel = "Probe"
                whiteText = true
                break;
            case SubscriptionType.Team:
                color = "red"
                coreLabel = "Team"
                whiteText = true
                break;
            case SubscriptionType.FreeVegetables:
                color = "pink"
                coreLabel = "Umsonstanteil"
                break;
        }
        let label = `${coreLabel}`
        if (this.start_at && this.start_at.isAfter(date)) {
            label += " ab " + this.start_at.format("DD.MM.YYYY")
            opacity = true
        } else if (this.end_at && this.end_at.isBefore(date)) {
            label = "Ausgetreten"
            coreLabel = "Ausgetreten"
            whiteText = true
            color = "red"
        }

        return {
            color,
            label,
            coreLabel,
            whiteText,
            opacity
        }
    }
}
