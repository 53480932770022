import { Warning } from "@mui/icons-material";
import { sendToBackend } from "../../helper/websocketHelper";
import { Button, Typography, Stack, Box, useMediaQuery } from "@mui/material"
import CurrencyInput from "react-currency-input-field";
import theme from "../../theme";
import styled from "@emotion/styled";

export const Income = ({ data }) => {
  //console.log("income", data)

  const tabletUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleSubmit = (event) => {
    event.preventDefault();

    let cents = new FormData(event.currentTarget).get("income");
    if (cents.indexOf(",") === cents.length - 1 || cents.indexOf(",") === -1) {
      cents = cents + ",00"
    } else if (cents.indexOf(",") === cents.length - 2) {
      cents = cents + "0"
    }
    cents = cents.replace(/\./g, "").replace(/€ /g, "").replace(/,/g, "");

    const workdays = new FormData(event.currentTarget).get("workdays");

    sendToBackend("submit", { cents: parseInt(cents), workdays: parseInt(workdays) });
  };

  return (
    <CustomBox>
      <Typography variant="h1" color="primary" textAlign="center">Einkommensabgabe</Typography>
      <Box sx={{ padding: 2, maxWidth: "60%", border: "2px solid black", margin: "0 auto 20px" }}>
        <Stack justifyContent="center" alignItems="center" sx={{ padding: 2 }} direction={tabletUp ? "row" : "column"}>
          <Warning color="warning" sx={{ fontSize: "3rem" }} />

          <Typography variant="h5" textAlign="center">Bitte notiere dir dein eingegebenes Einkommen und die Ackereinsätze. Es wird anonymisiert gespeichert und kann nicht eingesehen werden!</Typography>
          <Typography variant="h5" textAlign="center">Please note your income, it is not possible to check later what you filled in here!</Typography>
        </Stack>
      </Box>
      <Typography textAlign="center" sx={{ padding: 2 }}>{data.interaction.submit.has_submitted_income ? "Du hast dein, bzw. euer Einkommen und geplante Arbeitseinsätze schon abgegeben." : "Auf dieser Seite kannst du dein, bzw. euer monatliches Einkommen und geplante Arbeitseinsätze abgeben."}</Typography>
      <form onSubmit={handleSubmit}>
        <InputStack direction={tabletUp ? "row" : "column"} spacing={2} justifyContent="center" sx={{ padding: "25px"}}>
          <CurrencyInput
            id="income"
            name="income"
            placeholder="Einkommen"
            defaultValue={data.interaction.submit.has_submitted_income ? data.interaction.submit.income : ""}
            decimalsLimit={2}
            prefix="€ "
            groupSeparator="."
            decimalSeparator=","
            allowNegativeValue={false}
            autoFocus={true}
            required
          />
          <CurrencyInput
            id="workdays"
            name="workdays"
            placeholder="Ackereinsätze"
            defaultValue={data.interaction.submit.has_submitted_income ? data.interaction.submit.workdays : ""}
            decimalsLimit={0}
            suffix=" Ackereinsätze"
            transformRawValue={(value) => {
              if (value.indexOf("Ackereinsätze") !== -1) {
                return value.substring(0, value.indexOf("Ackereinsätze"));
              }
              return value;
            }}
            groupSeparator="."
            decimalSeparator=","
            allowNegativeValue={false}
            autoFocus={false}
            required
          />
          <Button variant="contained" type="submit" color="primary">{data.interaction.submit.has_submitted_income ? "korrigieren" : "abschicken"}</Button>
        </InputStack>
      </form>

      <Box sx={{ padding: 2 }}>

        <Typography>//english version below//</Typography>
        <Typography variant="h2">Wie funktionierts?</Typography>
        <Typography variant="h3">Wichtig: Der neue Richtwert bleibt ein Richtwert, ihr könnt weiterhin über die Höhe eures Gebots frei entscheiden!</Typography>

        <Typography variant="h5">Schritt 1:</Typography>
        <Typography>Jede Person aus eurer Mitgliedschaft, die vom GaCo-Gemüse isst, ermittelt ihr eigenes monatliches Netto-Einkommen (siehe Hinweis unten "Was zählt ins Nettoeinkommen").</Typography>

        <Typography variant="h5">Schritt 2:</Typography>
        <Typography>Bildet die Summe aus den einzelnen Netto-Einkommen, also das Gesamteinkommen des Haushalts.</Typography>

        <Typography variant="h5">Schritt 3:</Typography>
        <Typography>Teilt die Summe eurer Netto-Einkommen aus Schritt 2 durch die Anzahl eurer Haushaltsmitglieder. Das ist eurer Durchschnitt-Haushalt-Netto-Einkommen, dass ihr auf dieser Website eingebt (die Anzahl eurer Anteile wird automatisch berücksichtigt).</Typography>
        <Typography>Achtung - Unterschied zu den Übungen im letzten Jahr: wir teilen durch die tatsächliche Anzahl an Personen! Damit erheben wir das Nettodurchschnittseinkommen, und nicht das Nettoäquivalenzeinkommen. </Typography>
        <Typography>Hier findet ihr einen Infoflyer mit einer Rechenhilfe für die Ermittlung eures Einkommens (runterscrollen zu Dateien: "INFOS_Flyer_Bietverfahren_2023-06-06"):</Typography>
        <Typography><a href="https://www.gartencoop.org/tunsel/node/7567" target="_blank" rel="noreferrer">www.gartencoop.org/tunsel/node/7567</a>.</Typography>

        <Typography>Dort findet ihr auch mehr Informationen zum neuen Bietverfahren und zum Prozess, wie dieses entstanden ist.</Typography>

        <Typography variant="h5">Was zählt ins Nettoeinkommen?</Typography>
        <ul>
          <li>Netto-Lohn/ Gehalt (auch Bonuszahlungen, Weihnachtsgeld o.Ä.)</li>
          <li>Ersatzleistungen, andere staatliche Leistungen (z.B. Kindergeld, Bürgergeld, etc.)</li>
          <li>Netto-Einkommen aus selbständiger Arbeit nach Abzug von Versicherung, Steuern etc.</li>
          <li>Unterhaltszahlungen</li>
          <li>Kapitalerträge</li>
          <li>Mieteinnahmen</li>
          <li>Rente/ Pension/ …</li>
        </ul>

        <Typography variant="h5">Haushalt:</Typography>
        <Typography>Als Mitglied eines Haushalts gelten alle Menschen, die gemeinsam eine Mitgliedschaft haben und sich das GaCo-Gemüse teilen (egal ob sie zusammen wohnen oder nicht). Auch Kinder gelten als Haushaltsmitglieder.</Typography>

        <Typography variant="h5">Datenschutz:</Typography>
        <Typography>Jede Mitgliedschaft erhält einen Link mit einem Pseudonym, mit dem das Einkommen auf der Website hinterlegt werden kann. Auf der Webseite werden die Pseudonyme ohne Zuordnung zur Mitgliedschaft abgespeichert. Das Einkommen kann später nicht mehr eingesehen werden. Durch Eingabe eines neuen Betrags kann das Einkommen später allerdings noch korrigiert werden.
          Zu jedem Pseudonym ist abgespeichert, wie viele Anteile die Mitgliedschaft dahinter erhält. D.h. es kann nachvollzogen werden, ob ein abgegebenes Einkommen zu einer Mitgliedschaft mit ganzem oder halben Anteil gehört.
          Für das Büroteam ist einsehbar, ob und welche Mitgliedschaft ihr Einkommen schon abgegeben hat, nicht aber die Höhe des Einkommens.
          Ab 10 abgegebenen Einkommen kann die Liste der Einkommen (ohne Zuordnung zur Mitgliedschaft) vom Büroteam heruntergeladen werden. Hiermit wird ein Prozentsatz ermittelt, der als Richtwert für die eigentliche Bietrunde verwendet wird.
          Die Zuordnung der Pseudonyme zu Mitgliedschaften ist zu keinem Zeitpunkt auf dem Server abgespeichert.</Typography>

        <Typography>Wenn ihr nach den Erklärungen noch Fragen habt, schreibt eine Mail an: <a href="mailto:bietverfahren@gartencoop.org">bietverfahren@gartencoop.org</a></Typography>
        <Typography variant="h5">Video-Erklärung gibts hier:</Typography>
        <video src="/GaCo-Bietverfahren-Video_Start2025.m4v" width={tabletUp ? "750" : "100%"} height={tabletUp ? "500" : "auto"} controls></video>

        <Typography variant="h2">Abfrage Ackereinsätze 2025</Typography>
        <Typography>Zu wie vielen Ackereinsätzen plant ihr im Jahr 2025 zu gehen?</Typography>
        <Typography>Mit Hilfe dieser Angabe können die Gärtner:innen besser planen, mit wie viel Unterstützung sie auf dem Acker rechnen können. Bitte daher nur Ackereinsätze und nicht andere Einsätze wie Fahrradverteilung, Mitarbeit in AGs etc. angeben.
          Beispiel: Aus eurer Mitgliedschaft möchten zwei Personen zusammen zu vier Einsätzen kommen, dann tragt bitte 8 Einsätze in das Eingabefeld ein.</Typography>

      </Box>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h2">english version:</Typography>

        <Typography variant="h5">Step 1:</Typography>
        <Typography>Every person that is part of your membership and eats the GaCo-veggies, calculates their own monthly net income.
          (see explanation below: "What is included in the net-income?").</Typography>

        <Typography variant="h5">Step 2:</Typography>
        <Typography>Add all the individual net incomes, the sum is the net income of your household.</Typography>

        <Typography variant="h5">Step 3:</Typography>
        <Typography>Divide the net income of your household by the number of people in your household. In this case the household is the people you share your veggies with, it does not matter if you actually live together or not. The result is the average net income of your household that you fill in on this website.</Typography>
        <Typography>The amount of shares you have is being considered automatically.</Typography>

        <Typography>You will find a pdf-document (in German) which can help you with the calculation of your income on the website ("INFOS_Flyer_Bietverfahren_2023-06-06")</Typography>
        <Typography><a href="https://www.gartencoop.org/tunsel/node/7567" target="_blank" rel="noreferrer">www.gartencoop.org/tunsel/node/7567</a>.</Typography>

        <Typography>On this site, you will also find a flyer with more information on this new procedure and the reasons for it, more details on the new concept and the process we went through to develop it.</Typography>

        <Typography variant="h5">What is included in the net income?</Typography>
        <ul>
          <li>net wage (including bonuses, e.g. christmas bonus or others)</li>
          <li>government benefits, social security, public services (e.g. child benefit, housing help, Bürgergeld, etc.) </li>
          <li>net income from self-employment (less taxes, social insurance contributions)</li>
          <li>alimony</li>
          <li>yield on capital</li>
          <li>rental incomes</li>
          <li>pension</li>
        </ul>

        <Typography variant="h5">Household:</Typography>
        <Typography>All the people who share one membership and therefor the GaCo-veggies are considered one household (no matter whether they live together or not). Kids are also counted as members of the household.</Typography>

        <Typography variant="h5">Data privacy:</Typography>
        <Typography>Every membership gets a personalized link with a pseudonym, where they fill in their income on the website. The pseudonyms are stored on the website with no information on the actual membership. It is not possible to see the income after submitting it. It can however be modified by submitting a new value. The information on how many shares one membership has, is stored on the website linked to the pseudonym. That means it is possible to find out if the income is for half a share or an entire share. The support team of the Gartencoop can see which memberships already submitted their income but cannot see the concrete amount. When at least 10 memberships submitted their income, the support team can download a list of the incomes (without correlation between income and individual membership). This list will be the base to calculate the percentage which is going to be the orientation value for the pay-what-you-can round. At no moment the correlation pseudonym to membership will be recorded on the server.</Typography>

        <Typography>If you have any questions contact us: <a href="mailto:bietverfahren@gartencoop.org">bietverfahren@gartencoop.org</a></Typography>

        <Typography variant="h5">video-tutorial (in german)</Typography>
        <video src="/GaCo-Bietverfahren-Video_Start2025.m4v"  width={tabletUp ? "750" : "100%"} height={tabletUp ? "500" : "auto"}  controls></video>

        <Typography variant="h2">Query on farm work shifts for 2025</Typography>
        <Typography>In how many farm work shifts are you planning to take part in 2025? </Typography>
        <Typography>This will help the gardeners plan how much assistance they are likely to get. Please therefor only count farm work shifts, not other shifts such as bicycle shifts, group work, etc. Example: when two people plan to come together to work on the farm on four occasions this counts as eight shifts. </Typography>
      </Box>
    </CustomBox>
  )
}


const InputStack = styled(Stack)(() => ({
  "& input": {
    padding: "10px",
  },
}));

const CustomBox = styled(Box)(()=> ({
  "& h5": {
    marginTop: "32px"
  }
}))