import { Box, Button, Drawer } from "@mui/material"
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import logo from "../assets/images/logo.svg"
import theme from "../theme";
import Login from "./Login";
import images from "../assets/images/start/index.ts"

export type StartPageProps = {
  data: any,
  loggedIn: boolean,
  staticTexts: any,
  modifyUsers: boolean,
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
images.sort((a, b) => 0.5 - Math.random());

export const Start = ({ data, loggedIn, staticTexts, modifyUsers }: StartPageProps) => {
  const [loginDrawer, setLoginDrawer] = useState(false)
  // useEffect(() => {
  //     if(loggedIn){
  //         //navigate to home page
  //         window.location.href = "/"
  //     }
  // },[loggedIn])

  return (
    <Box sx={{
      overflow: "hidden",
      height: "100vh",
    }}>

      <GridContainer>
        {[...Array(13).keys()].map((index) => {
          return (
            <div className={` grid-item ${alphabet[index]}`} key={index}>
              <img alt="" src={images[index]} />
            </div>
          )
        })}
      </GridContainer>


      <Logo component={Link} to="/News"><img src={logo} alt="gaco logo" /></Logo>
      {!loggedIn ?
        <LoginButton onClick={() => setLoginDrawer(true)}>
          {staticTexts.login}
        </LoginButton>
        :
        <LoginButton component={Link} to="/News">
          hallo {data.interaction.username.value}
        </LoginButton>
      }
      <Drawer
        anchor="right"
        open={loginDrawer}
        onClose={() => setLoginDrawer(false)}
      >
        <Login staticTexts={staticTexts} closeDrawer={() => setLoginDrawer(false)} modifyUsers={modifyUsers} />
      </Drawer>
    </Box>
  )
}

const Logo = styled(Box)(() => ({
  marginBottom: "1rem",
  border: "1px solid rgba(0, 0, 0, 0.8)",
  position: "absolute",
  width: "30vh",
  height: "30vh",
  top: "60vh",
  right: "10vw",
  borderRadius: "50%",
  background: "#fff",
  padding: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: "0.85",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",

  "img": {
    width: "100%",
  },

  [theme.breakpoints.up("md")]: {
    width: "40vh",
    height: "40vh",
    top: "30vh",
    right: "20vw",
  },
}));

const LoginButton = styled(Button)(() => ({
  width: "10vh",
  height: "10vh",
  top: "60vh",
  right: "10vw",
  marginBottom: "1rem",
  border: "5px solid black",
  position: "absolute",
  borderRadius: "50%",
  backgroundColor: theme.palette.lightgreen,
  color: theme.palette.black,
  fontWeight: "bold",
  padding: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.65)",
    color: "white"
  },

  [theme.breakpoints.up("md")]: {
    width: "10vh",
    height: "10vh",
    top: "30vh",
    right: "20vw",
  },
}));


const GridContainer = styled(Box)(() => ({
  display: "grid",
  gap: "5px",
  width: "100vw",
  height: "100vh",
  padding: "5px auto",

  gridTemplateAreas: `
  "a c e"
  "a c e"
  "a f f"
  "a f f"
  "d f f"
  "h h b"
  "h h g"
  "i i i"
  "i i i"
  "k k l"
  "k k l"
  "j m m"
  "j m m"
  "j m m"
  `,

  [theme.breakpoints.up("md")]: {
    gridTemplateAreas: `
    "a a a b b b c c c e e e"
    "a a a b b b c c c e e e"
    "j j f f f f c c c h h h"
    "j j f f f f c c c h h h"
    "g g f f f f k k k h h h"
    "g g f f f f k k k h h h"
    "d d d d i i i i l l m m"
    "d d d d i i i i l l m m"`,
  },

  ".grid-item": {
    position: "relative",
    borderRadius: "12px",
    overflow: "hidden",

    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0)",

    transition: "transform 0.5s ease-in-out",

    ":hover": {
      //zIndex: 1,
      transform: "scale(1.02)",
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    },

    "img": {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    }
  },

  ".a": {
    gridArea: "a",
  },

  ".b": {
    gridArea: "b",
  },

  ".c": {
    gridArea: "c",
  },

  ".d": {
    gridArea: "d",
  },

  ".e": {
    gridArea: "e",
  },

  ".f": {
    gridArea: "f",
  },

  ".g": {
    gridArea: "g",
  },

  ".h": {
    gridArea: "h",
  },

  ".i": {
    gridArea: "i",
  },

  ".j": {
    gridArea: "j",
  },

  ".k": {
    gridArea: "k",
  },

  ".l": {
    gridArea: "l",
  },

  ".m": {
    gridArea: "m",
  },

  ".n": {
    gridArea: "n",
  },

  ".o": {
    gridArea: "o",
  }
}));