import { useState } from 'react';
import { Typography, Button, Drawer, Container } from '@mui/material';
import Login from "./Login";
import styled from '@emotion/styled';
import PanToolIcon from '@mui/icons-material/PanTool';
import theme from "../theme";

function AccessDenied({ data }) {
  const [loginDrawerOpen, setLoginDrawerOpen] = useState(false);
  const modifyUsers = data?.interaction?.modify_users.value;

  return (
    <CenteredContainer>
      <Hand />
      <Typography variant="h2">{data.interaction.static_texts.value.access_denied_title}</Typography>
      <Typography>{data.interaction.static_texts.value.access_denied_text}</Typography>
      <Typography>{data.interaction.static_texts.value.access_denied_login}</Typography>
      <LoginButton variant="outlined" onClick={() => setLoginDrawerOpen(true)}>{data.interaction.static_texts.value.login}</LoginButton>
      <Drawer
        anchor="right"
        open={loginDrawerOpen}
        onClose={() => setLoginDrawerOpen(false)}
      >
        <Login staticTexts={data.interaction.static_texts.value} closeDrawer={() => setLoginDrawerOpen(false)} modifyUsers={modifyUsers} />
      </Drawer>
    </CenteredContainer>
  )
}

export default AccessDenied


// Styling
const LoginButton = styled(Button)(() => ({
  border: "none",
  borderRadius: 30,
  background: theme.palette.darkgreen,
  color: theme.palette.white,
  margin: "40px 0",
  "&:hover": {
    border: "none",
    background: theme.palette.darkgreen,
  }
}));

const Hand = styled(PanToolIcon)(() => ({
  fontSize: 80,
  color: theme.palette.stone,
}));

const CenteredContainer = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
}));

