import { Box, Typography, Stack } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import Editor from "./modules/Editor";

function VP({ data }) {
  const document = data.interaction.vp.value;
  const texts = data.interaction.texts.value;

  return (
    <Wrapper className="vp">
      <Typography variant="h2">{texts.title}</Typography>
      {document.opening && (
        <Typography variant="h4">{texts.opening_hours}: <br/> {document.opening}</Typography>
      )}

      <Stack className="vp__infobox">
        <Box>
          {document.code && <Typography><strong>{texts.code}:</strong> {document.code}</Typography>}
          {(
            <Typography><strong>{texts.members}:</strong> {document.members_count}</Typography>
          )}
          {(
            <Typography><strong>{texts.waiting_members}:</strong> {document.waiting_members_count}</Typography>
          )}
          {document.contact && (
            <Typography><strong>{texts.contact}:</strong> {document.contact}</Typography>
          )}
        </Box>
        <Box>
          {document.case_storage && (
            <Typography><strong>{texts.case_storage}:</strong> {document.case_storage}</Typography>
          )}
          {document.created_at && (
            <Typography><strong>{texts.created_at}</strong> {new Date(document.created_at).toLocaleDateString('de-DE', {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}</Typography>
          )}
          {document.status && (
            <Typography><strong>{texts.status}:</strong> {document.status}</Typography>
          )}
          {document.space && <Typography>{texts.space}: {document.space}</Typography>}
        </Box>
      </Stack>
      {document.body && (
        <>
          <Typography variant="h2">{texts.infos}</Typography>
          <Editor body={document.body} />
        </>
      )}
      {document.members.length > 0 && (
        <>
          <Typography variant="h2">{texts.members}</Typography>
          <ul>
            {document.members.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        </>
      )}
    </Wrapper>
  );
}

export default VP;


const Wrapper = styled(Stack)(() => ({
  padding: "0 20px",

  [theme.breakpoints.up("md")]: {
    padding: "20px 0",
  },

  ".vp": {
    "&__infobox": {
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "0 20px 20px",

      [theme.breakpoints.up("md")]: {
        gap: 100,
      },
      "& p": {
        paddingBottom: "10px",
        
        "& strong": {
          display: "inline",
        }
      }
    },
  },

  h4: {
    margin: "0 20px 20px",
  },
}));