import React from 'react'
import { Box, TextField, Typography, Container, Button } from "@mui/material";
import { sendToBackend } from '../helper/websocketHelper';
import styled from '@emotion/styled';
import theme from "../theme";

export const ForgotPassword = ({ data }) => {
  const texts = data.interaction.texts.value;
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const formdata = new FormData(event.currentTarget);
    console.log(formdata.get("email"))

    sendToBackend("forgot_email", {
      value: formdata.get('email'),
    })
  };

  return (
    <Container>
      <Typography variant='h2'>
        {texts.header}
      </Typography>
      <Typography>
        {texts.text}
      </Typography>
      <StyledBox component="form" onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="email"
          label="E-Mail"
          type="email"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 4 }}
        >
          {texts.button}
        </Button>
      </StyledBox>
    </Container>
  )
}

const StyledBox = styled(Box)(() => ({
  marginTop: 1,

  [theme.breakpoints.up("sm")]: {
    width: "50%;"
  },

  [theme.breakpoints.up("md")]: {
    width: "40%;"
  },
}));