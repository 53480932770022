import React, { useState } from 'react'
import { Box, TextField, Stack, Typography, Container, Button } from "@mui/material";
import { sendToBackend } from '../helper/websocketHelper';
import styled from '@emotion/styled';
import theme from "../theme";
import { Error } from '@mui/icons-material';

export const ActivateUser = ({ data }) => {
    const texts = data.interaction.texts.value;
    const [error, setError] = useState(null);

    const tokenValid = data.interaction.token_valid.value;
        
    const handleSubmit = (event) => {
        event.preventDefault();
        const formdata = new FormData(event.currentTarget);
        const password = formdata.get('password');
        console.log(password.length)

        if (password.length < 8 ) {
            setError(texts.passwords_too_short)
        } else if (password !== formdata.get('password2')){
            setError(texts.passwords_not_matching)
        } else {
            setError(null);
            sendToBackend("password", {
                value: password,
            })
        }
    };
  
    return (
      <Container sx={{ width: "100%", margin:"0 auto"}}>
        <Typography variant='h2'>
          {texts.change_password}
        </Typography>

        { tokenValid ?
        (<StyledBox component="form" onSubmit={handleSubmit}>
            <TextField
                required
                fullWidth
                name="password"
                label={texts.password}
                type="password"
                id="password"
            />
            <TextField
                required
                fullWidth
                name="password2"
                label={texts.password2}
                type="password"
                id="password2"
            />
            {error && (
                <Stack direction="row" gap={2} justifyContent="center">
                    <Error color='red' />
                    <Errormessage>{error}</Errormessage>
                </Stack>
            )}
            <Button
            type="submit"
            fullWidth
            variant="contained"
            >
            {texts.change_password}
            </Button>
        </StyledBox>)
        :
        (<>
            <Typography>{texts.link_not_valid}</Typography>
            <Button href="/forgot_password" variant='contained' color="primary" sx={{marginTop: "10px" }}>{texts.create_new_link}</Button>
        </>)
        }
      </Container>
    )
  }
  
  const StyledBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    marginTop: 30,
    gap: 20,
  
    [theme.breakpoints.up("sm")]: {
      width: "50%;"
    },
  
    [theme.breakpoints.up("md")]: {
      width: "40%;"
    },
  }));

  const Errormessage = styled(Typography)(() => ({
    color: theme.palette.red,

  }))