import { Box, Button, Select, TextField, FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { useState } from "react";
import styled from "@emotion/styled";
import { sendToBackend } from "../helper/websocketHelper";
import theme from "../theme";

function User({ data }) {
  const [error, setError] = useState(false);

  const texts = data.interaction.texts.value;

  return (
    <Wrapper className="membership">
      <Typography variant="h2">{texts.change_profile_header}</Typography>
      <div className="flexcontainer">
        <div className="column">
          <Typography variant="h5">{texts.user}</Typography>
            <Typography variant="body1">Name: {data.interaction.username.value}</Typography>
            <Typography variant="body1">Email: {data.interaction.email.value}</Typography>
{/*           <h4>Email-Adresse ändern</h4>
          <TextField 
            id="change_email"
            label="deine Email-Adresse"
            variant="outlined"
            placeholder={data.interaction.email.value}
          />
          <Button variant="contained" onClick={()=> {
            const newEmail = document.querySelector("#change_email");
            sendToBackend("email", {value: newEmail.value})
          }}>
            Email-Adresse ändern
          </Button> */}
        </div>

        <div className="column">
        <Typography variant="h5">{texts.change_language}</Typography>
        <FormControl fullWidth>
          <InputLabel id="languageswitch-label">{texts.language}</InputLabel>
          <Select
            labelId="languageswitch-label"
            id="languageswitch"
            value={data.interaction.language.value}
            label={texts.language}
            className="select"
            onChange={(event) => sendToBackend("language", {value: event.target.value})}
          >
            <MenuItem value={"German"}>deutsch</MenuItem>
            <MenuItem value={"English"}>english</MenuItem>
          </Select>
        </FormControl>
        </div>
        <div className="column">
        <Typography variant="h5">{texts.change_password}</Typography>
          <TextField
            id="new_pw_1"
            label={texts.password}
            variant="outlined"
            type="password"
            fullWidth
          />
          <TextField
            id="new_pw_2"
            label={texts.password2}
            variant="outlined"
            type="password"
            fullWidth
          />
          {error &&
            <div className="error">
              <ErrorIcon /> 
              <p>{texts.passwords_not_matching}</p>
            </div>
          }
          <Button variant="contained" onClick={() => {
            const newPw1 = document.getElementById("new_pw_1");
            const newPw2 = document.getElementById("new_pw_2");

            if (newPw1.value === newPw2.value) {
              sendToBackend("password", {value: newPw1.value});
              setError(false);
            } else {
              setError(true);
            }
          }}>
            {texts.change_password}
          </Button>
        </div>
        </div>
    </Wrapper>
    )
}

export default User;

const Wrapper = styled(Box)(() => ({
  padding: "0 20px",

  ".flexcontainer": {
    display: "flex",
    padding: "0 20px",
    justifyContent:"space-evenly",
    flexDirection: "column",
    gap: 10,
    
    [theme.breakpoints.up("md")]: {
      gap: 20,
      flexDirection: "row"
    },
  },

  ".column": {
    flex: "1 1 100%",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    gap: 10,

    [theme.breakpoints.up("md")]: {
      gap: 20,
    },

    "h4": {
      textAlign: "center"
    }
  },

  ".select": {
    width: "100%",
    minWidth: 200,
  }, 

  ".error": {
    display: "flex",
    gap: 5,
    alignItems: "center",
    color: "red"
  }
}));