import { useState } from "react";
import EditDocument from "./modules/EditDocument";
import { CalendarMonth, LocationOn, Person, Topic } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@mui/icons-material/Link";
import { Typography, Box, IconButton, Snackbar, Stack } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";

function DocumentHeader({
  title,
  topic,
  created_at,
  created_by,
  body,
  id,
  start_at,
  end_at,
  location,
  category,
}) {
  // Snackbar (confirm copy to clipboard)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <StyledHeadline variant="h3">
        {title}
        {start_at && (
          <Typography variant="h6">
            am{" "}
            {new Date(start_at).toLocaleString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        )}
      </StyledHeadline>
      <SublineDateAndAuthor>
        <DocInfoBox>
          {start_at && end_at && (
            <Typography className="inner">
              <CalendarMonth />
              {new Date(start_at).toLocaleString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
              {end_at !== start_at &&
                new Date(end_at).toLocaleString("de-DE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
            </Typography>
          )}
          {location && (
            <Typography className="inner">
              <LocationOn />
              {location.name}
            </Typography>
          )}
          {topic && (
            <TopicText sx={{ paddingLeft: "30px" }} className="inner">
              <Topic />
              {topic}
            </TopicText>
          )}
          {category && (
            <Typography className="inner">
              <Topic />
              {category}
            </Typography>
          )}
          {created_by && (
            <Typography className="inner">
              <Person /> {created_by}
              {` (am   
              ${new Date(created_at).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })})`}
            </Typography>
          )}
        </DocInfoBox>
        <ButtonBox>
          <IconButton>
            <EditDocument body={body} id={id} />
          </IconButton>
          {/*  TODO: Link stimmt so nicht (gaco.uber statt localhost) */}
          <CopyToClipboard text={window.location.href}>
            <IconButton onClick={() => setOpenSnackbar(true)}>
              <LinkIcon />
            </IconButton>
          </CopyToClipboard>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleClose}
            message="Link in die Zwischenablage kopiert"
            action=""
          />
        </ButtonBox>
      </SublineDateAndAuthor>
    </>
  );
}

export default DocumentHeader;

const StyledHeadline = styled(Typography)(() => ({
  borderLeft: `${theme.palette.yellow} 20px inset`,
  borderTop: `${theme.palette.white} 10px outset`,
  borderBottom: `${theme.palette.white} 10px inset`,
  background: `${theme.palette.yellow}44`,
  padding: 20,
  marginLeft: 0,
  width: "85%",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    marginLeft: "-20px",
    paddingLeft: 30,
  },
}));

const TopicText = styled(Typography)(() => ({
  padding: "20px 0 0 0",
  [theme.breakpoints.up("md")]: {
    padding: "0 0 0 20px",
  },
}));

const SublineDateAndAuthor = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: "0 30px 30px",
  margin: "20px 0 20px -16px",
  width: "100%",
  borderBottom: `${theme.palette.lightgreen} 3px solid`,
  "& .inner": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginBottom: 5,
    alignItems: "end",
    lineHeight: 1,
  },

  [theme.breakpoints.up("md")]: {
    padding: "0 30px 10px",
    margin: "10px auto",
    "& .inner": {
      width: "auto",
    },
  },
}));

const DocInfoBox = styled(Stack)(() => ({
  maxWidth: "75%",
  gap: 20,

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "end",
    flex: "1 0 75%",
    maxWidth: "100%",
    gap: 30
  },
}));

const ButtonBox = styled(Stack)(() => ({
  flexDirection: "column",
  justifyContent: "flex-end",
  flex: "0 1 15%",
  paddingRight: 10,

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  "& button .MuiBox-root": {
    height: "24px",
  },
}));
