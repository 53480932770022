import { UserInfos } from "./AdminMembership"
import { Button, Stack, Drawer } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Flare, Close } from "@mui/icons-material";
import React from "react";

type PopUpUsersProps = {
    allUsers,
    onSelect: (member: UserInfos) => void,
    open: boolean,
    onClose: () => void,
}

export const PopUpUsers = ({ allUsers, onSelect, open, onClose }: PopUpUsersProps) => {
    const [pageSize, setPageSize] = React.useState(10);

    const columns = [
        {
            field: "is_main_user",
            key: "mainUser",
            headerName: "Hauptnutzer",
            width: 100,
            renderCell: (props) =>
            <Stack sx={{justifyContent: "center", alignItems: "center", width: "100%"}}>
                {props.row.is_main_user ? <Flare color="success" /> : <Close color="error" />}
                </Stack>
        },
        { field: "id", headerName: "ID", width: 100},
        { field: "name", headerName: "Name", width: 150 },
        { field: "username", headerName: "Username", width: 150 },
        { field: "phone", headerName: "Telefon", width: 150 },
        { field: "membership_id", headerName: "Mitgliedschaft", width: 120 },
        //{ field: "created_at", headerName: "Angemeldet seit", width: 250 },
        {
            headerName: "",
            key: "action",
            width: 300,
            renderCell: (props) => {
                return (
                    <Button variant="contained" color="primary" onClick={() => onSelect(props.row.id)}>zur Mitgliedschaft hinzufügen</Button>
                )
            }
        },
    ];

    return (
        <Stack justifyContent="center">
            <Drawer
                anchor="bottom"
                open={open}
                onClose={onClose}
                sx={{
                    maxWidth: "75%",
                }}>
                <div style={{ display: "flex", height: "100%", minHeight: "30vh", justifyContent: "center", padding: "50px" }}>
                    <div style={{ flexGrow: 0.6 }}>
                        <DataGridPro
                            autoHeight
                            components={{ Toolbar: GridToolbar }}
                            columns={columns}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 200 },
                                }
                            }}
                            pagination
                            pageSize={pageSize}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rows={allUsers}
                            sx={{ height: "500px" }}
                        />
                    </div>
                </div>
            </Drawer>
        </Stack>
    )
}
