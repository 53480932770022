import 'remirror/styles/all.css';
import {
    BlockquoteExtension,
    BoldExtension,
    BulletListExtension,
    HeadingExtension,
    ItalicExtension,
    LinkExtension,
    ListItemExtension,
    MarkdownExtension,
    OrderedListExtension,
    StrikeExtension,
    TableCellExtension,
    TableExtension,
    TableRowExtension,
    TrailingNodeExtension,
    UnderlineExtension,
} from 'remirror/extensions';
import { WysiwygToolbar } from '@remirror/react-ui';
import { ExtensionPriority } from 'remirror';
import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import styled from '@emotion/styled';


const Editor = (props: { id?: number, body: string, onChange?: (data: string) => void }) => {
    //console.log(props.id, props.body)
    const { manager, state, onChange, getContext } = useRemirror({
        extensions: () => [
            new LinkExtension({ autoLink: true }),
            new BoldExtension({}),
            new StrikeExtension(),
            new ItalicExtension(),
            new HeadingExtension({}),
            new BlockquoteExtension(),
            new BulletListExtension({ enableSpine: true }),
            new OrderedListExtension(),
            new ListItemExtension({ priority: ExtensionPriority.High, enableCollapsible: true }),
            new TrailingNodeExtension(),
            new TableExtension(),
            new TableCellExtension(),
            new TableRowExtension(),
            new UnderlineExtension(),
            new MarkdownExtension({ copyAsMarkdown: false }),
        ],
        content: props.body,
        stringHandler: 'markdown',

    });
    return (
        <EditorContent className='remirror-theme'>
            <Remirror onChange={(change) => {
                if (props.onChange) {
                    const helpers = getContext().helpers;
                    const markdown = helpers.getMarkdown();
                    props.onChange(markdown);
                }
                onChange(change);
            }} manager={manager} initialContent={state} editable={!!props.id}>
                {/* The text editor is placed above the menu to make the zIndex easier to manage for popups */}
                {!!props.id && <WysiwygToolbar />}
                <EditorComponent />
            </Remirror>
        </EditorContent>
    );
};

export default Editor;

const EditorContent = styled("div")(() => ({
    "--rmr-color-border": "transparent",
    "& .remirror-editor.ProseMirror": {
        overflowY: "auto" as "auto"
    }
  }));
