import { Container, Stack, Button, Typography, Dialog, TextField } from "@mui/material";
import React from "react";
import { Download, Settings } from "@mui/icons-material";
import { saveSvgAsPng } from "save-svg-as-png";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { sendToBackend } from "../helper/websocketHelper";

export default function BiddingAdmin({ data }) {
  const [openPopup, setOpenPopup] = React.useState(false);
  const document = data.interaction;
  const setup = document.setup.value;
  const overview = document.overview.value;

  const [formData, setFormData] = React.useState({
    start_at: setup?.start_at,
    end_at: setup?.end_at,
    goal_cents: setup?.goal_cents,
    goal_members: setup?.goal_members,
    correction_until: setup?.correction_until,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    sendToBackend("setup", formData);
    setOpenPopup(false)
  };

  return (
    <Container>
      <Stack className="bidding__details__inner">
        <Typography variant="h2">Übersicht Gebote</Typography>
        <Stack className="bidding__details__inner__box" direction="row" justifyContent="space-between" marginBottom={5}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ margin: 2 }}
          >
            <Stack className="bidding__details__inner__box__text">
              <Typography>
                {overview.bidding_shares}
              </Typography>
              <Typography>
                {overview.bidding_members}
              </Typography>
              <Typography>{overview.average}</Typography>
              <Typography>
                {overview.extrapolated}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{ margin: 2 }}
          >
            <Button
              href={overview.csv}
              startIcon={<Download />}
              variant="outlined"
              size="large"
              sx={{ width: 300 }}
              color="secondary"
            >
              Gebote.csv
            </Button>
            <Button
              onClick={() =>
                saveSvgAsPng(
                  document.getElementsByClassName("poloto")[0],
                  "Gebotsverteilung.png"
                )
              }
              startIcon={<Download />}
              size="large"
              variant="outlined"
              color="secondary"
              sx={{ width: 300 }}
            >
              Gebotsverteilung.png
            </Button>
          </Stack>
          <Button variant="contained" sx={{ width: 300, height: "fit-content", margin: "auto 0" }} color="secondary" startIcon={<Settings />} onClick={() => setOpenPopup(true)}>Einstellungen</Button>
        </Stack>
        {overview && (
          <Stack
            className="bidding_details_inner_imagecontainer"
            alignItems="center"
            dangerouslySetInnerHTML={{
              __html: overview.distribution_plot,
            }}
          ></Stack>
        )}
        <Typography variant="h3">Erklärungen</Typography>
        <div>
          <p>Der Extrapolierte Wert wird so berechnet:
            <ul>
              <li>1. Es wird das durchschnittliche Gebot ermittelt, dafür wird bei jeder Mitgliedschaft das Gebot durch die Anzahl der Anteile geteilt. So ergibt sich ein Durchschnittswert pro Anteil.</li>
              <li>2. Dieser Durchschnittswert wird mit der Anzahl der Anteile und 12 (Monate) multipliziert, um den Gesamtwert zu erhalten.</li>
            </ul>
          </p>
          <p>In der Grafik werden die Gebot/Anteile eingetragen: Ein Gebot von 100€ bei einem Anteil und ein Gebot von 50€ bei 0,5 Anteile landen also beide bei 100€.</p>
        </div>
      </Stack>
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <form onSubmit={handleSubmit}>
          <Stack direction="column" gap={2} sx={{ margin: 2, padding: 2 }} >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="start_at"
                label="Start"
                value={formData.start_at}
                inputFormat="DD.MM.YYYY"
                onChange={(newValue) => {
                  setFormData({ ...formData, start_at: newValue.format('YYYY-MM-DD') })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                id="end_at"
                label="Ende"
                inputFormat="DD.MM.YYYY"
                value={formData.end_at}
                onChange={(newValue) => {
                  setFormData({ ...formData, end_at: newValue.format('YYYY-MM-DD') })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <TextField
                id="goal_cents"
                label="Zielbetrag / Monat in Cent"
                type="number"
                inputProps={{ min: "0", step: "1" }}
                value={formData.goal_cents}
                onChange={(e) => {
                  setFormData({ ...formData, goal_cents: parseInt(e.target.value) })
                }}
              />
              <TextField
                id="goal_members"
                label="Ziel Mitglieder"
                value={formData.goal_members}
                type="number"
                inputProps={{ min: "0", step: "1" }}
                onChange={(e) => {
                  setFormData({ ...formData, goal_members: parseInt(e.target.value) })
                }}
              />
              <DatePicker
                id="correction_until"
                label="Korrektur bis"
                inputFormat="DD.MM.YYYY"
                value={formData.correction_until}
                onChange={(newValue) => {
                  setFormData({ ...formData, correction_until: newValue.format('YYYY-MM-DD') })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button type="submit" variant="contained">Abschicken</Button>
          </Stack>
        </form>
      </Dialog>


    </Container >
  );
}
