import { useState } from "react";
import { Box, Chip, Button, Dialog, Stack, TextField, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { sendToBackend } from "../../helper/websocketHelper";
import React from "react";
import moneyFormater from "../../helper/moneyFormater";

export default function AdminMembershipTabDeposits({ data }) {
    let sum = 0;
    data.forEach(row => {
        sum += row.cents;
    });

    const [openPopup, setOpenPopup] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [donation, setDonation] = useState(sum);
    const [description, setDescription] = useState("Spende");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const columns = [
        {
            field: "date", headerName: "Datum", flex: .5, renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }) : ""
        },
        {
            field: "cents", headerName: "Betrag", flex: .5, renderCell: (params) => {
                return moneyFormater(params.value);
            }
        },
        { field: "description", headerName: "Beschreibung", flex: 1 },
        {
            field: "type", headerName: "Typ", flex: 1, renderCell: (params) =>
                params.value === "Payment"
                    ? params.row.cents > 0 ? <Chip label="Einzahlung" color="success" /> : <Chip label="Rückzahlung" color="secondary" />
                    : <Chip label="Spende" color="primary" />
        },
        {
            fields: "edit", headerName: "", flex: 2, renderCell: (params) => {
                return <Stack direction="row" gap={2}>
                    <Button variant="contained" onClick={() => { setCurrentRow(params.row); setDescription(params.row.description); setOpenPopup(true); }} >Beschreibung anpassen</Button>
                    {(params.row.cents < 0) ? <Button variant="contained" color="error" onClick={() => { setCurrentRow(params.row); setOpenDeletePopup(true); }} >Löschen</Button> : <></>}
                </Stack>
            }
        }
    ];

    return (
        <>
            <Box style={{ display: "flex", height: "100%" }}>
                <Box style={{ flexGrow: 1 }}>
                    <DataGridPro
                        autoHeight
                        getRowId={(row) => row.id}
                        components={{ Toolbar: GridToolbar }}
                        columns={columns}
                        pagination
                        rows={data}
                        sx={{ height: "500px" }}
                    />
                </Box>
            </Box>
            <Button variant="contained" size="large" sx={{ margin: "10px 0 0 auto" }} onClick={() => { setDescription("Spende"); setDonation(sum); setCurrentRow(null); setOpenPopup(true); }}>
                Neue Spende anlegen
            </Button>
            <Dialog open={openPopup} onClose={() => setOpenPopup(false)} >
                <Stack sx={{ p: 4 }} direction="column" gap={2} justifyContent="center" alignItems="center">
                    {(currentRow !== null) ? <></> : <TextField
                        id="payback"
                        label="Spende in Cent"
                        onChange={(e) => setDonation(parseInt(e.target.value))}
                        type="number"
                        value={donation}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />}
                    <TextField
                        id="description"
                        label="Beschreibung"
                        onChange={(e) => setDescription(e.target.value)}
                        type="string"
                        value={description}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                    {(currentRow !== null) ? <></> : <Typography variant="h6">Spende: {moneyFormater(donation)} </Typography>}
                    <Button
                        variant="contained"
                        disabled={donation > sum || donation <= 0 || isNaN(donation)}
                        onClick={() => {
                            if (currentRow === null) {
                                sendToBackend("create_deposit_donation", { cents: donation, description: description });
                            } else {
                                sendToBackend("set_deposit_description", { id: currentRow.id, description: description });
                            }
                            setOpenPopup(false);
                        }}>
                        Speichern
                    </Button>
                </Stack>
            </Dialog>
            <Dialog open={openDeletePopup} onClose={() => setOpenDeletePopup(false)} >
                <Stack sx={{ p: 4 }} direction="column" gap={2} justifyContent="center" alignItems="center">
                    <Typography variant="h6">Soll der Eintrag wirklich gelöscht werden?</Typography>
                    <Stack direction="row" gap={2}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                sendToBackend("delete_deposit", { id: currentRow.id });
                                setOpenDeletePopup(false);
                            }}>
                            Ja
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpenDeletePopup(false);
                            }}>
                            Nein
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    )
}
