import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { deDE } from "@mui/material/locale";
import { deDE as deutsch } from "@mui/x-data-grid/locales";

let theme = createTheme(
  {
    palette: {
      primary: {
        main: "#729a87",
        light: "#ccddca",
        dark: "#729a87",
      },
      secondary: {
        main: "#d5cabf",
      },
      white: "#ffffff",
      black: "#000000",
      stone: "#d5cabf",
      lightgreen: "#ccddca",
      darkgreen: "#729a87",
      yellow: "#e9b528",
      orange: "#b86b28",
      brown: "#81371c",
      red: "#d04344",
    },
    typography: {
      fontFamily: [
        "Metropolis",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        color: "orange",
        fontSize: 60,
        fontWeight: 400,
        margin: 30,
      },
      h2: {
        color: "black",
        fontSize: 30,
        fontWeight: 600,
        margin: "20px 0",
      },
      h3: {
        color: "black",
        fontSize: 28,
        fontWeight: 300,
      },
      h4: {
        color: "black",
        fontSize: 24,
        fontWeight: 300,
      },
      h5: {
        color: "black",
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 10,
      },
      h6: {
        color: "black",
        fontSize: 18,
        fontWeight: 100,
      },
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          root: {
            background: "white",
            borderRadius: 0,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            transition: "transform 150ms ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
              transition: "transform 150ms ease-in-out",
            },
          },
        },
      },
    },
  },
  deDE,
  deutsch
);

theme = responsiveFontSizes(theme);

export default theme;
